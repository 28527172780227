import React, { useContext, useEffect, useState } from 'react'
import { isDematUser } from '../utils/AuthUtil'
import { DrawerContext } from '../Provider/DrawerContext'
import { navbarData, navbarDataNonDemat } from '../constants/navbar'
import { useNavigate } from 'react-router-dom'
import goals from '../assets/Navbar/goals.svg'
import ShoppingBag from '../assets/Navbar/ShoppingBag.svg'
import ArrowRight from '../assets/Navbar/ArrowRight.svg'
import Timeline from '../assets/Navbar/Timeline.svg'
import KYC from '../assets/Navbar/kyc.svg'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from './ui/sheet'
import { useSetRecoilState } from 'recoil'
import { messageModalState } from '../store/Feedback'

const BottomNavbar = () => {
  const { isDrawerOpen } = useContext(DrawerContext)
  const [showNavbar, setShowNavbar] = useState(true)
  const [openDiscover, setOpenDiscover] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const navigate = useNavigate()
  const [openMore, setOpenMore] = useState(false)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [lastScrollY, setLastScrollY] = useState(0)

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }
    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  return (
    <>
      {isDematUser() && !isDrawerOpen && (
        <div>
          <div
            className={`transition-transform duration-300 ease-in-out ${
              showNavbar ? 'translate-y-0' : 'translate-y-[150%]'
            } w-full fixed bottom-0 left-0 right-0 bg-white h-20 flex lg:hidden justify-between gap-x-2 px-2 border-t border-[#D3DBEC] z-[10]`}
            style={{
              boxShadow: '0px -4px 24px 0px rgba(65, 85, 141, 0.16)',
            }}
          >
            {navbarData.slice(0, 4).map((item, index) => (
              <div
                key={item.id}
                className='flex flex-col justify-center w-full items-center mt-3 mb-4 relative gap-y-1 cursor-pointer'
                onClick={() => {
                  setActiveIndex(index)
                  //   clevertap.event.push('Clicked Button', {
                  //     Action: item.link,
                  //     Page_name: location.pathname,
                  //   })
                  if (item.title === 'Discover') {
                    setOpenDiscover(!openDiscover)
                  } else {
                    navigate(item.link)
                    setOpenDiscover(false)
                  }
                }}
              >
                {index !== 2 && (
                  <span
                    className={`
                        ${
                          openMore &&
                          item.title === 'More' &&
                          !openDiscover &&
                          ' icon-filled-400 bg-[#ECEEF4] rounded-[40px] flex justify-center items-center'
                        }
                ${
                  activeIndex === index &&
                  !openMore &&
                  ' icon-filled-400 bg-[#ECEEF4] rounded-[40px] flex justify-center items-center'
                } text-primary-500 h-8 w-8 flex items-center justify-center`}
                  >
                    {activeIndex === index ? item?.iconIsActive : item?.icon}
                  </span>
                )}
                {index === 2 && (
                  <div className={`text-primary-500 flex flex-col items-center justify-end h-full`}>
                    <div className='flex bg-primary-500 absolute -top-[28px] rounded-full p-1.5 shadow-[0px_4px_16px_0px_rgba(65,85,141,0.16)]'>
                      <img
                        src={goals}
                        className='stroke-primary-50 w-[42px] h-[42px] min-w-[42px]'
                      />
                    </div>
                    <p
                      className={`font-semibold text-xs -mt-4 ${
                        location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                      }`}
                    >
                      {item.title}
                    </p>
                  </div>
                )}
                {index !== 2 && (
                  <p
                    className={`font-semibold text-xs ${
                      location.pathname === item.link || (item.title === 'Discover' && openDiscover)
                        ? 'text-primary-500'
                        : 'text-primary-400'
                    }`}
                  >
                    {item.title}
                  </p>
                )}
              </div>
            ))}

            <div
              className='flex flex-col justify-center w-full items-center mt-3 mb-4 relative gap-y-1 cursor-pointer'
              onClick={() => {
                setOpenMore(!openMore), setOpenDiscover(false)
              }}
            >
              <span
                className={`${
                  location.pathname === '`shanti`' &&
                  !openMore &&
                  'icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
                } 

               ${
                 openMore &&
                 navbarData[4].title === 'More' &&
                 'icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
               } text-primary-500 py-1 px-5`}
              >
                {navbarData[4].icon}
              </span>

              <Sheet open={openMore}>
                <SheetContent side='bottom' className='rounded-t-[24px] h-auto'>
                  <SheetHeader className='mb-6'>
                    <SheetTitle className='font-DMSans text-[20px] kerning-[28px] -tracking-[0.8px] text-black-500 font-semibold'>
                      More Investment Options
                    </SheetTitle>
                  </SheetHeader>
                  <div className='grid grid-cols-3'>
                    {navbarData.slice(5, 11).map((item, index) => (
                      <div
                        key={item.id}
                        className='flex flex-col justify-center items-center relative min-h-28 min-w-27 gap-y-2'
                        onClick={() => {
                          setActiveIndex(index), navigate(item.link)
                        }}
                      >
                        <img src={item.icon} alt={item.title} />
                        <p
                          className={`font-DMSans font-semibold text-l text-black-500 -tracking-[0.64px]`}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </SheetContent>
              </Sheet>

              <p
                className={`font-semibold text-xs ${
                  location.pathname === navbarData[5].link ? 'text-primary-500' : 'text-primary-400'
                }`}
              >
                {navbarData[4].title}
              </p>
            </div>
          </div>

          {openDiscover && (
            <div
              style={{
                background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.8) 40%, #FFFFFF 65%)',
              }}
              className={`fixed top-0 left-0 bottom-20 w-screen bg-white flex flex-col justify-end z-[1]`}
            >
              <div className={`flex items-center justify-center w-full`}>
                {/* Left Border */}
                <div
                  className='h-[2px] flex-grow'
                  style={{
                    background: 'linear-gradient(90deg, rgba(211, 219, 236, 0) 0%, #D3DBEC 100%)',
                  }}
                ></div>

                {/* Heading */}
                <h1 className='mx-4 text-xl font-DMSans font-semibold text-primary-800'>
                  Discover
                </h1>

                {/* Right Border */}
                <div
                  className='h-[2px] flex-grow'
                  style={{
                    background: 'linear-gradient(90deg, #D3DBEC 0%, rgba(211, 219, 236, 0) 100%)',
                  }}
                ></div>
              </div>

              <div className={`w-full px-5 mt-6 mb-4`}>
                <div
                  className='py-4 border-b border-[#D3DBEC] flex justify-between items-center'
                  onClick={() => {
                    navigate('/dashboard/mutual-funds'), setOpenDiscover(false)
                  }}
                >
                  <div className='flex gap-3 items-center'>
                    <img src={ShoppingBag} alt='ShoppingBagIcon' height={28} width={24} />
                    <p className='font-semibold font-DMSans text-base text-primary-500'>
                      Mutual Funds{' '}
                      <button className='py-[2px] px-1 text-xs text-white bg-secondary-500 rounded-sm box-border'>
                        NEW!
                      </button>
                    </p>
                  </div>
                  <div>
                    <img src={ArrowRight} alt='Right Arrow' />
                  </div>
                </div>
                <div
                  className='py-4 flex justify-between items-center'
                  onClick={() => {
                    navigate('/dashboard/theme'), setOpenDiscover(false)
                  }}
                >
                  <div className='flex gap-3 items-center'>
                    <img src={Timeline} alt='TimelineIcon' height={28} width={24} />
                    <p className='font-semibold font-DMSans text-base text-primary-500'>
                      Exchange Traded Funds (ETFs)
                    </p>
                  </div>
                  <div>
                    <img src={ArrowRight} alt='Right Arrow' />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {!isDematUser() && location.pathname !== '/dashboard/theme-details' && (
        <div>
          <div
            className={`transition-transform duration-300 ease-in-out ${
              showNavbar ? 'translate-y-0' : 'translate-y-[150%]'
            } w-screen fixed bottom-0 left-0 bg-white h-20 flex lg:hidden no-underline justify-around gap-x-2 px-2 border-t border-[#D3DBEC] z-[10] shadow-[0px_-4px_24px_0px_rgba(65,85,141,0.12)]`}
          >
            {navbarDataNonDemat.slice(0, 5).map((item, index) => (
              <div
                key={item.id}
                className='flex flex-col justify-center items-center mt-3 mb-4 relative gap-y-1'
                onClick={() => {
                  setActiveIndex(index)
                  if (index === 2) {
                    setMessageModalState({
                      message: 'Your KYC is incomplete.',
                      linkMessage:
                        'Click here to complete your KYC and to enable hassle free, one-click investing.',
                      severity: 'info',
                    })
                  } else if (index === 1) {
                    setOpenDiscover(!openDiscover)
                  } else {
                    navigate(item.link)
                    setOpenDiscover(false)
                  }
                }}
              >
                {index != 2 && (
                  <span
                    className={`${
                      activeIndex === index && 'icon-filled-400 bg-[#ECEEF4] rounded-[40px] '
                    } text-primary-500 h-8 w-8 flex justify-center items-center`}
                  >
                    {activeIndex === index ? item?.iconIsActive : item?.icon}
                  </span>
                )}

                {index === 2 && (
                  <div className={`text-primary-500 flex flex-col items-center justify-end h-full`}>
                    <div className='flex bg-primary-500 absolute -top-[28px] rounded-full p-1.5 shadow-[0px_4px_16px_0px_rgba(65,85,141,0.16)]'>
                      <img src={KYC} className='stroke-primary-50 w-[42px] h-[42px] min-w-[42px]' />
                    </div>
                    <p
                      className={`font-semibold text-xs -mt-4 ${
                        location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                      }`}
                    >
                      {item.title}
                    </p>
                  </div>
                )}

                {index != 2 && (
                  <p
                    className={`font-semibold text-xs ${
                      location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                    }`}
                  >
                    {item.title}
                  </p>
                )}
              </div>
            ))}
          </div>

          {openDiscover && (
            <div
              style={{
                background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.8) 40%, #FFFFFF 65%)',
              }}
              className={`fixed top-0 left-0 bottom-20 w-screen bg-white flex flex-col justify-end no-underline`}
            >
              <div className={`flex items-center justify-center w-full`}>
                {/* Left Border */}
                <div
                  className='h-[2px] flex-grow'
                  style={{
                    background: 'linear-gradient(90deg, rgba(211, 219, 236, 0) 0%, #D3DBEC 100%)',
                  }}
                ></div>

                {/* Heading */}
                <h1 className='mx-4 text-xl font-DMSans font-semibold text-primary-800'>
                  Discover
                </h1>

                {/* Right Border */}
                <div
                  className='h-[2px] flex-grow'
                  style={{
                    background: 'linear-gradient(90deg, #D3DBEC 0%, rgba(211, 219, 236, 0) 100%)',
                  }}
                ></div>
              </div>

              <div className={`w-full px-5 mt-6 mb-4`}>
                <div className='py-4 border-b border-[#D3DBEC] flex justify-between items-center'>
                  <div
                    onClick={() => {
                      navigate('/dashboard/mutual-funds'), setOpenDiscover(false)
                    }}
                    className='flex gap-3 items-center'
                  >
                    <img src={ShoppingBag} alt='ShoppingBagIcon' height={28} width={24} />
                    <p className='font-semibold font-DMSans text-base text-primary-500'>
                      Mutual Funds{' '}
                      <button className='py-[2px] px-1 text-xs text-white bg-secondary-500 rounded-sm box-border'>
                        NEW!
                      </button>
                    </p>
                  </div>
                  <div>
                    <img src={ArrowRight} alt='Right Arrow' />
                  </div>
                </div>
                <div className='py-4 flex justify-between items-center'>
                  <div
                    onClick={() => {
                      navigate('/dashboard/theme'), setOpenDiscover(false)
                    }}
                    className='flex gap-3 items-center'
                  >
                    <img src={Timeline} alt='TimelineIcon' height={28} width={24} />
                    <p className='font-semibold font-DMSans text-base text-primary-500'>
                      Exchange Traded Funds (ETFs)
                    </p>
                  </div>
                  <div>
                    <img src={ArrowRight} alt='Right Arrow' />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default BottomNavbar
