import { useState, useEffect } from 'react'

export function usePaymentProcessingTimer(duration = 120) {
  const [progress, setProgress] = useState(0)
  const [timeLeft, setTimeLeft] = useState(duration)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer)
          return 0
        }
        return prevTime - 1
      })

      setProgress((prevProgress) => {
        const newProgress = prevProgress + 100 / duration
        return newProgress > 100 ? 100 : newProgress
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [duration])

  return { progress, time: timeLeft }
}
