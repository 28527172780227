/* eslint-disable */
import { Box } from '@mui/system'
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Link,
  Modal,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { useRecoilState } from 'recoil'
import { messageModalState } from '../store/Feedback'
import { useEffect, useState } from 'react'
import Popup from './Popup'
import badge from '../assets/images/badge.png'
import account_balance from '../assets/images/account_balance.png'
import Aadhar from '../assets/images/Aadhar.png'
import edit_document from '../assets/images/edit_document.png'
import signature from '../assets/images/signature.png'
import { useNavigate } from 'react-router-dom'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './ui/alert-dialog'
import AlertGreen from '../assets/AlertIcons/AlertGreen.svg'

const MessageModal = () => {
  const [modelState, setModelState] = useRecoilState(messageModalState)
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const [openKYCModal, setOpenKYCModal] = useState(false)
  const [openKYCModalMobile, setOpenKYCModalMobile] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [openErrorModalMobile, setOpenErrorModalMobile] = useState(false)
  const [openInfoModalMobile, setOpenInfoModalMobile] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    if (modelState.callback) {
      modelState.callback()
    }
    setModelState(null)
  }

  const handleConfirmation = (callback) => {
    setOpen(false)
    if (callback) {
      callback()
    }
    setModelState(null)
  }

  useEffect(() => {
    if (modelState && modelState.message) {
      setOpen(true)
    }
  }, [modelState])

  useEffect(() => {
    if (modelState?.severity === 'info' && modelState?.linkMessage && open) {
      if (isMobile) {
        setOpenKYCModalMobile(true)
      } else {
        setOpenKYCModal(true)
      }
    }
  }, [modelState, open, isMobile])

  useEffect(() => {
    if (modelState?.severity === 'info' && !modelState.linkMessage && open) {
      if (isMobile) {
        setOpenInfoModalMobile(true)
      } else {
        setOpenInfoModal(true)
      }
    }
  }, [modelState, open, isMobile])

  useEffect(() => {
    if (modelState?.severity === 'error') {
      if (isMobile) {
        setOpenErrorModalMobile(true)
      } else {
        setOpenErrorModal(true)
      }
    }
  }, [modelState, isMobile])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    maxWidth: { md: '400px' },
    width: { xs: '90%' },
    borderRadius: '5px',
  }

  return (
    <>
      {open && (
        <Modal
          onClose={() => {
            if (modelState.severity === 'error' || modelState.confirmation) {
              return
            } else {
              handleClose()
            }
          }}
          open={open}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            {modelState.severity === 'error' &&
              (isMobile ? (
                <Popup
                  title={<Typography>&nbsp;</Typography>}
                  handleClose={() => {
                    setOpen(false)
                    setOpenErrorModalMobile(false)
                  }}
                  openModal={openErrorModalMobile}
                  width={500}
                  alignBottom
                  hideClose
                  sx={{ padding: '24px 0px 0px 0px' }}
                  background='#F4F7FECC'
                >
                  <Box
                    sx={{ textAlign: { xs: 'center', md: 'right' } }}
                    className='flex flex-col justify-center items-center'
                  >
                    <Typography
                      component='span'
                      sx={{
                        float: 'right',
                        background: '#ff1744',
                        my: 2,
                        padding: '3px 12px',
                        display: 'inline-block',
                        borderRadius: '100px',
                        border: '12px solid #FFEDED',
                      }}
                    >
                      <i className='fa-solid fa-exclamation' style={{ color: '#fff' }}></i>{' '}
                    </Typography>
                    <Typography fontWeight={500} sx={{ color: '#000', my: 1, mt: 2 }}>
                      {modelState?.title ? modelState?.title : 'Something went wrong!'}
                    </Typography>
                    <Typography component='p'>{modelState.message}</Typography>
                    <Typography component='span'>{modelState.additionaInfo}</Typography>
                  </Box>
                  <Box sx={{ textAlign: { xs: 'center', md: 'right' }, mt: 2 }}>
                    <Button
                      color='primary'
                      variant='outlined'
                      type='submit'
                      sx={{
                        py: '10px',
                        mx: 1,
                        px: '24px',
                        width: { xs: '100%', md: 'min-content' },
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '500',
                        fontFamily: 'DM Sans',
                        textTransform: 'none',
                        borderRadius: { xs: '12px', md: '8px' },
                        boxShadow: 'none',
                        whiteSpace: 'nowrap',
                        '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                        '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                      }}
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </Popup>
              ) : (
                <Popup
                  style={{ backgroundColor: 'red' }}
                  openModal={openErrorModal}
                  title={
                    <Box sx={{ textAlign: { xs: 'center', md: 'center' } }}>
                      <Typography
                        component='span'
                        sx={{
                          background: '#ff1744',
                          padding: '3px 12px',
                          display: 'inline-block',
                          borderRadius: '100px',
                          border: '12px solid #FFEDED',
                        }}
                      >
                        <i className='fa-solid fa-exclamation' style={{ color: '#fff' }}></i>{' '}
                      </Typography>
                    </Box>
                  }
                  width={500}
                  background='#F4F7FECC'
                  shadow='0px 8px 24px 0px #41558D1F'
                  border='1px solid #D3DBEC'
                >
                  <Box sx={{ textAlign: { xs: 'center', md: 'center' } }}>
                    <Typography
                      fontWeight={600}
                      sx={{ color: '#000', fontSize: '20px', letterSpacing: '-4%' }}
                    >
                      {modelState?.message ? modelState?.message : 'Something went wrong!'}
                    </Typography>
                    <Typography
                      component='p'
                      fontWeight={400}
                      sx={{ color: '#575757', fontSize: '16px', lineHeight: '24px' }}
                    >
                      {/* {modelState.message} */}
                    </Typography>
                    <Typography component='span'>{modelState.additionaInfo}</Typography>
                  </Box>
                  <Box sx={{ textAlign: { xs: 'center', md: 'center' }, mt: 2 }}>
                    <Typography
                      component='p'
                      sx={{ color: theme.palette.primary.text, fontSize: '14px' }}
                    >
                      <button
                        type='button'
                        className='px-6 py-2.5 text-[14px] w-full md:w-min kerning-[20px] text-primary-500 font-medium rounded-[12px] md:rounded-[8px] border border-[#D3BEC] shadow-[0_4px_4px_0_rgba(65,85,141,0.04)] hover:shadow-[0_4px_6px_0_rgba(65,85,141,0.08)] transition-shadow'
                        onClick={() => {
                          setOpenErrorModal(false)
                          setOpen(false)
                        }}
                      >
                        Close
                      </button>
                    </Typography>
                  </Box>
                </Popup>
              ))}

            {modelState.severity === 'info' &&
              (isMobile ? (
                <Popup
                  title={<Typography>&nbsp;</Typography>}
                  handleClose={() => {
                    setOpen(false)
                    setOpenInfoModalMobile(false)
                  }}
                  openModal={openInfoModalMobile}
                  width={500}
                  alignBottom
                  sx={{ padding: '24px 0px 0px 0px' }}
                >
                  <Box
                    sx={{ textAlign: { xs: 'center', md: 'right' } }}
                    className='flex flex-col justify-center items-center'
                  >
                    <Typography
                      component='span'
                      sx={{
                        float: 'right',
                        background: '#41558D',
                        my: 2,
                        padding: '3px 12px',
                        display: 'inline-block',
                        borderRadius: '100px',
                        border: '12px solid #eceef4',
                      }}
                    >
                      <i className='fa-solid fa-exclamation' style={{ color: '#fff' }}></i>{' '}
                    </Typography>

                    <Typography component='p'>{modelState.message}</Typography>
                    <Typography component='span'>{modelState.additionaInfo}</Typography>
                  </Box>
                  <Box sx={{ textAlign: { xs: 'center', md: 'right' }, mt: 2 }}>
                    <Button
                      color='primary'
                      variant='outlined'
                      type='submit'
                      sx={{
                        py: '10px',
                        mx: 1,
                        px: '24px',
                        width: { xs: '100%', md: 'min-content' },
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '500',
                        fontFamily: 'DM Sans',
                        textTransform: 'none',
                        borderRadius: { xs: '12px', md: '8px' },
                        boxShadow: 'none',
                        whiteSpace: 'nowrap',
                        '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                        '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                      }}
                      onClick={() => handleConfirmation(modelState?.callback)}
                    >
                      Close
                    </Button>
                  </Box>
                </Popup>
              ) : (
                <Popup
                  style={{ backgroundColor: '#41558D' }}
                  openModal={openInfoModal}
                  title={
                    <Box sx={{ textAlign: { xs: 'center', md: 'center' } }}>
                      <Typography
                        component='span'
                        sx={{
                          background: '#41558D',
                          padding: '3px 12px',
                          display: 'inline-block',
                          borderRadius: '100px',
                          border: '12px solid #eceef4',
                        }}
                      >
                        <i className='fa-solid fa-exclamation' style={{ color: '#fff' }}></i>{' '}
                      </Typography>
                    </Box>
                  }
                  width={500}
                  background='#F4F7FECC'
                  shadow='0px 8px 24px 0px #41558D1F'
                  border='1px solid #D3DBEC'
                >
                  <Box sx={{ textAlign: { xs: 'center', md: 'center' } }}>
                    <Typography
                      component='p'
                      fontWeight={400}
                      sx={{ color: '#575757', fontSize: '16px', lineHeight: '24px' }}
                    >
                      {modelState.message}
                    </Typography>
                    <Typography component='span'>{modelState.additionaInfo}</Typography>
                  </Box>
                  <Box sx={{ textAlign: { xs: 'center', md: 'center' }, mt: 2 }}>
                    <Typography
                      component='p'
                      sx={{ color: theme.palette.primary.text, fontSize: '14px' }}
                    >
                      <button
                        type='button'
                        className='px-6 py-2.5 text-[14px] w-full md:w-min kerning-[20px] text-primary-500 font-medium rounded-[12px] md:rounded-[8px] border border-[#D3BEC] shadow-[0_4px_4px_0_rgba(65,85,141,0.04)] hover:shadow-[0_4px_6px_0_rgba(65,85,141,0.08)] transition-shadow cursor-pointer'
                        onClick={() => {
                          setOpenInfoModal(false)
                          handleConfirmation(modelState?.callback)
                        }}
                      >
                        Close
                      </button>
                    </Typography>
                  </Box>
                </Popup>
              ))}
          </Box>
        </Modal>
      )}

      <Popup
        name='kyc-popup'
        handleClose={() => {
          setOpenKYCModal(false)
          setOpen(false)
        }}
        openModal={openKYCModal}
        width={550}
      >
        <Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              component='p'
              sx={{
                background: '#FF6666',
                padding: '2px 12px 1px 12px',
                display: 'inline-block',
                borderRadius: '100px',
                border: '12px solid #FFEDED',
                textAlign: 'center',
              }}
            >
              <i className='fa-solid fa-exclamation' style={{ color: '#fff' }}></i>{' '}
            </Typography>
          </Box>
          <Typography
            component='p'
            sx={{
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '28px',
              textAlign: 'center',
              mt: 2,
              mb: 1,
              color: '#2D2D2D',
            }}
          >
            Complete your KYC to proceed
          </Typography>
          <Typography
            component='p'
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              textAlign: 'center',
              mb: 2,
              color: '#575757',
            }}
          >
            To start investing, we&apos;re required to verify your identity. Please keep the
            following documents in handy before proceeding:
          </Typography>
          <Box sx={{ border: '1px solid #D3DBEC', borderRadius: '16px' }}>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img src={badge} alt='pan-card-image' style={{ width: '28px', height: '28px' }} />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  PAN Card
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                  }}
                >
                  PAN Number and DOB as per PAN Card
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img src={Aadhar} alt='aadhar-image' style={{ width: '28px', height: '28px' }} />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Aadhaar Card
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                  }}
                >
                  Phone number must be linked with your Aadhaar
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img
                src={account_balance}
                alt='account-balance-image'
                style={{ width: '28px', height: '28px' }}
              />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Bank Details
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                  }}
                >
                  IFSC Code & Bank Account Number
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img
                src={signature}
                alt='signature-image'
                style={{ width: '28px', height: '28px' }}
              />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Signature
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                  }}
                >
                  Photo or Scan Copy for verification
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img
                src={edit_document}
                alt='document-image'
                style={{ width: '28px', height: '28px' }}
              />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Blank Paper and Pen
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                  }}
                >
                  For proof of liveness check
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Button
              variant='outlined'
              sx={{
                borderRadius: '8px',
                p: '10px 24px 10px 24px',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
              }}
              onClick={() => {
                setOpenKYCModal(false)
                setOpen(false)
              }}
            >
              Remind me later
            </Button>
            <Button
              variant='contained'
              sx={{
                borderRadius: '8px',
                p: '10px 24px 10px 24px',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                ml: 2,
              }}
              onClick={() => {
                setOpenKYCModal(false)
                setOpen(false)
                window.open('https://onboarding.fabits.com', '_blank')
              }}
            >
              Complete KYC
            </Button>
          </Box>
        </Box>
      </Popup>
      <Popup
        name='kyc-popup-mobile'
        handleClose={() => {
          setOpenKYCModalMobile(false)
          setOpen(false)
        }}
        openModal={openKYCModalMobile}
        width={550}
        alignBottom
      >
        <Box>
          <Typography
            component='p'
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              mb: '4px',
              color: '#2D2D2D',
            }}
          >
            Complete your KYC to proceed
          </Typography>
          <Typography
            component='p'
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              mb: 3,
              color: '#6E6E6E',
            }}
          >
            To start investing, we&apos;re required to verify your identity. Please keep the
            following documents in handy before proceeding:
          </Typography>
          <Box sx={{ border: '1px solid #D3DBEC', borderRadius: '16px' }}>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img src={badge} alt='pan-card-image' style={{ width: '24px', height: '24px' }} />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  PAN Card
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                  }}
                >
                  PAN Number and DOB as per PAN Card
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img src={Aadhar} alt='aadhar-image' style={{ width: '24px', height: '24px' }} />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  Aadhaar Card
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                  }}
                >
                  Phone number must be linked with your Aadhaar
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img
                src={account_balance}
                alt='account-balance-image'
                style={{ width: '24px', height: '24px' }}
              />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  Bank Details
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                  }}
                >
                  IFSC Code & Bank Account Number
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: '1px solid #D3DBEC',
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img
                src={signature}
                alt='signature-image'
                style={{ width: '24px', height: '24px' }}
              />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  Signature
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                  }}
                >
                  Photo or Scan Copy for verification
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                p: '8px 4px 8px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img
                src={edit_document}
                alt='document-image'
                style={{ width: '24px', height: '24px' }}
              />
              <Box sx={{}}>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  Blank Paper and Pen
                </Typography>
                <Typography
                  component='p'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                  }}
                >
                  For proof of liveness check
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Button
              variant='contained'
              sx={{
                borderRadius: '8px',
                p: '10px 24px 10px 24px',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              }}
              fullWidth
              onClick={() => {
                setOpenKYCModalMobile(false)
                setOpen(false)
                window.open('https://onboarding.fabits.com', '_blank')
              }}
            >
              Complete KYC
            </Button>
          </Box>
        </Box>
      </Popup>
    </>
  )
}

export default MessageModal
