/* eslint-disable */

import { CommandGroup, CommandItem, CommandList, CommandInput } from './command'
import { Command as CommandPrimitive } from 'cmdk'
import { useState, useRef, useCallback, useEffect } from 'react'

import { Skeleton } from './skeleton'

import { Check } from 'lucide-react'
import { cn } from '../../lib/utils'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Button } from './button'
import { isDematUser } from '../../utils/AuthUtil'
import { sanitizeName } from '../../utils/Util'

export const AutoComplete = ({
  options,
  placeholder,
  emptyMessage,
  value,
  onValueChange,
  disabled,
  searchLoading,
  fabStarFunds,
}) => {
  const inputRef = useRef(null)
  const navigate = useNavigate()
  const [isOpen, setOpen] = useState(false)

  const handleKeyDown = useCallback(
    (event) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      // Keep the options displayed when the user is typing
      if (!isOpen) {
        setOpen(true)
      }

      // This is not a default behaviour of the <input /> field
      if (event.key === 'Enter' && input.value !== '') {
        const optionToSelect = options.find((option) => option.label === input.value)
        if (optionToSelect) {
          onValueChange?.(optionToSelect)
        }
      }

      if (event.key === 'Escape') {
        input.blur()
      }
    },
    [isOpen, options, onValueChange],
  )

  const handleBlur = useCallback(() => {
    setTimeout(() => setOpen(false), 200) // Close dropdown after slight delay
  }, [])

  function getFundReturn(fundPortfolio) {
    if (fundPortfolio?.threeYrRet != null && fundPortfolio?.threeYrRet != 0) {
      return `${fundPortfolio.threeYrRet.toFixed(2)}`
    } else if (fundPortfolio?.fiveYrRet != null && fundPortfolio?.fiveYrRet != 0) {
      return `${fundPortfolio.fiveYrRet.toFixed(2)}`
    } else if (fundPortfolio?.oneYrRet != null && fundPortfolio?.oneYrRet != 0) {
      return `${fundPortfolio.oneYrRet.toFixed(2)}`
    } else {
      return 0
    }
  }

  function getFundYear(fundPortfolio) {
    if (fundPortfolio?.threeYrRet != null && fundPortfolio?.threeYrRet != 0) {
      return `3Y`
    } else if (fundPortfolio?.fiveYrRet != null && fundPortfolio?.fiveYrRet != 0) {
      return `5Y`
    } else if (fundPortfolio?.oneYrRet != null && fundPortfolio?.oneYrRet != 0) {
      return `1Y`
    } else {
      return 0
    }
  }

  return (
    <CommandPrimitive onKeyDown={handleKeyDown}>
      <div>
        <CommandInput
          ref={inputRef}
          value={value}
          onValueChange={onValueChange}
          onFocus={() => setOpen(true)}
          onBlur={handleBlur}
          placeholder={placeholder}
          disabled={disabled}
          className='text-base text-white w-[500px]'
        />
      </div>
      <div className='relative mt-1'>
        <div
          className={cn(
            'animate-in fade-in-0 zoom-in-95 max-h-[60vh] hide-scrollbar overflow-y-scroll p-4 absolute top-0 z-50 w-full rounded-xl bg-white outline-none border',
            isOpen ? 'block' : 'hidden',
          )}
        >
          {!value && isDematUser() && (
            <div className='flex flex-col mb-4'>
              <div className='flex gap-x-2 items-center'>
                <Button
                  variant='simple'
                  size='simple'
                  onClick={() => {
                    navigate('/dashboard/mutual-funds/orders'), setOpen(false)
                  }}
                  className={`rounded-full font-medium text-xs border border-primary-200 px-4 py-2 bg-background text-primary-500`}
                >
                  Orders
                </Button>
                <Button
                  variant='simple'
                  size='simple'
                  onClick={() => {
                    navigate('/dashboard/mutual-funds/sips'), setOpen(false)
                  }}
                  className={`rounded-full font-medium text-xs border border-primary-200 px-4 py-2 bg-background text-primary-500`}
                >
                  SIPs
                </Button>
                <Button
                  variant='simple'
                  size='simple'
                  onClick={() => {
                    navigate('/dashboard/mutual-funds/investments'), setOpen(false)
                  }}
                  className={`rounded-full font-medium text-xs border border-primary-200 px-4 py-2 bg-background text-primary-500`}
                >
                  Investments
                </Button>
              </div>
            </div>
          )}

          {!value && (
            <div className='mt-5'>
              <h2 className='font-semibold text-primary-700 leading-6 tracking-[-4%]'>
                Fabstar funds
              </h2>
              <div>
                {fabStarFunds.state === 'hasValue' &&
                  fabStarFunds?.contents?.data?.map((fund, index) => {
                    const isLast = index === fabStarFunds?.contents?.data?.length - 1

                    return (
                      <div
                        className={cn('w-full cursor-pointer flex gap-x-2 items-center py-4', {
                          'border-b-[1px] border-primary-50': !isLast,
                        })}
                        key={fund?.amcCode}
                        onClick={() =>
                          navigate(`/dashboard/mutual-funds/details`, {
                            state: {
                              fundDetails: fund,
                              schemeCode: fund.schemeCode,
                            },
                          })
                        }
                      >
                        <img
                          src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                          className='h-9 w-9 rounded-sm'
                        />

                        <div className='w-full'>
                          <div className='flex justify-between'>
                            <h2 className='font-semibold leading-5 text-sm text-black-500 text-nowrap'>
                              {fund?.legalName ? fund?.legalName : fund?.sName}
                              ...
                            </h2>

                            <p
                              className={`font-semibold leading-5 text-sm text-secondary-600 text-nowrap`}
                            >
                              {fund?.threeYrRet.toFixed(2)}% <span className='text-xs'>(3Y)</span>
                            </p>
                          </div>
                          <p className='text-xs text-black-400 font-medium leading-4'>
                            Starts at{' '}
                            <span className='text-xs text-black-500 font-semibold'>
                              ₹ {toCurrency(fund?.minInvt)}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          )}

          {value && options?.length > 0 && !searchLoading && (
            <div>
              {options.map((option, index) => {
                const isLast = index === options.length - 1

                return (
                  <div
                    key={option.schemeCode}
                    value={option.schemeCode}
                    onMouseDown={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                    }}
                    className={cn('flex w-full items-center gap-2 cursor-pointer')}
                    onClick={() =>
                      navigate(`/dashboard/mutual-funds/details`, {
                        state: {
                          fundDetails: option,
                          schemeCode: option.schemeCode,
                        },
                      })
                    }
                  >
                    <div
                      className={cn('w-full cursor-pointer flex gap-x-2 items-center py-4', {
                        'border-b-[1px] border-primary-50': !isLast,
                      })}
                      key={option?.amcCode}
                    >
                      <img
                        src={`${env.MF_LOGOS_URL}/${option?.amcCode}.png`}
                        className='h-9 w-9 rounded-sm'
                      />

                      <div className='w-full'>
                        <div className='flex justify-between'>
                          <h2 className='font-semibold leading-5 text-sm text-black-500 text-nowrap'>
                            {sanitizeName(option)}
                          </h2>

                          <div
                            className={`font-semibold leading-5 text-sm text-secondary-600 flex justify-between items-center`}
                          >
                            <p className='flex-wrap'> {getFundReturn(option)}% </p>
                            <span className='text-xs'>({getFundYear(option)})</span>
                          </div>
                        </div>
                        <p className='text-xs text-black-400 font-medium leading-4'>
                          Starts at{' '}
                          <span className='text-xs text-black-500 font-semibold'>
                            ₹ {toCurrency(option?.minInvt)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          {!searchLoading && options.length === 0 && value && (
            <CommandPrimitive.Empty className='select-none rounded-sm px-2 py-3 text-center text-sm'>
              {emptyMessage}
            </CommandPrimitive.Empty>
          )}
        </div>
      </div>
    </CommandPrimitive>
  )
}
