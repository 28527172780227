/* eslint-disable */

import * as React from 'react'
import { TrendingUp } from 'lucide-react'
import { Label, Pie, PieChart } from 'recharts'

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card'
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  // ChartTooltip,
  // ChartTooltipContent,
} from '../ui/chart'

export const description = 'A donut chart with text'

const FundComposeChart = ({ holdings = [] }) => {
  // Safeguard for holdings to ensure it's always an array
  const groupedHoldings = holdings?.reduce((acc, holding) => {
    const { assetClass } = holding

    // Define specific asset classes
    const specificAssetClasses = ['Equity', 'Debt', 'Hybrid']

    // Check if the asset class belongs to specific ones
    if (specificAssetClasses.includes(assetClass)) {
      if (!acc[assetClass]) {
        acc[assetClass] = []
      }
      acc[assetClass].push(holding)
    } else {
      // If it's not one of the specific ones, add to "Others"
      if (!acc.Others) {
        acc.Others = []
      }
      acc.Others.push(holding)
    }

    return acc
  }, {})

  const chartData = Object.keys(groupedHoldings).map((assetClass) => {
    const totalPercentageHolding = groupedHoldings[assetClass].reduce((sum, per) => {
      const { holdingPercentage } = per
      return sum + holdingPercentage
    }, 0) // Start sum at 0

    return {
      assetClass,
      holdings: totalPercentageHolding, // Sum of all holding percentages
      fill:
        assetClass === 'Equity'
          ? '#2563EB'
          : assetClass === 'Debt'
          ? '#16A24A'
          : assetClass === 'Hybrid'
          ? '#9333EA'
          : '#DC2626',
    }
  })

  const chartConfig = {
    holdings: {
      label: 'holdings',
    },
    Equity: {
      label: 'Equity',
      color: '#2563EB',
    },
    Debt: {
      label: 'Debt',
      color: '#16A24A',
    },
    Hybrid: {
      label: 'Hybrid',
      color: '#9333EA',
    },
    Others: {
      label: 'Others',
      color: '#DC2626',
    },
  }

  return (
    <Card className='w-full border-none shadow-none'>
      {/* <CardHeader className='items-center pb-0'>
        <CardTitle>Pie Chart - Donut with Text</CardTitle>
        <CardDescription>January - June 2024</CardDescription>
      </CardHeader> */}
      <CardContent>
        <ChartContainer config={chartConfig} className='mx-auto aspect-square max-h-[300px]'>
          <PieChart>
            {/* <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} /> */}
            <Pie
              data={chartData}
              dataKey='holdings'
              nameKey='assetClass'
              cornerRadius={12}
              innerRadius={80}
              strokeWidth={5}
              paddingAngle={4}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor='middle'
                        dominantBaseline='middle'
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className='fill-foreground text-3xl font-bold'
                        >
                          {holdings?.length}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className='fill-muted-foreground'
                        >
                          Holdings in this Fund
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </Pie>
            <ChartLegend
              content={<ChartLegendContent nameKey='assetClass' />}
              className='-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center text-sm'
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
      {/* <CardFooter className='flex-col gap-2 text-sm'>
        <div className='flex items-center gap-2 font-medium leading-none'>
          Trending up by 5.2% this month <TrendingUp className='h-4 w-4' />
        </div>
        <div className='leading-none text-muted-foreground'>
          Showing total visitors for the last 6 months
        </div>
      </CardFooter> */}
    </Card>
  )
}

export default FundComposeChart
