/* eslint-disable */
import { MoveRight, PiggyBank, Plus } from 'lucide-react'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../Components/ui/tabs'
import { useEffect, useState } from 'react'
import { Button } from '../../Components/ui/button'
import { Refresh } from '@mui/icons-material'
import { MutualFundsOrders } from '../../store/MFStore'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import Loader from '../../Components/Loader/Loader'
import { Card, CardContent, CardHeader, CardTitle } from '../..//Components/ui/card'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Link } from 'react-router-dom'

const MFOrders = () => {
  const orders = useRecoilValueLoadable(MutualFundsOrders)
  console.log('🚀 ~ MFOrders ~ orders:', orders)
  const [groupedOrders, setGroupedOrders] = useState({})
  const [tabValue, setTabValue] = useState('Completed')
  const [expandedIndex, setExpandedIndex] = useState(null)
  const refreshOrder = useRecoilRefresher_UNSTABLE(MutualFundsOrders)

  // const groupByDate = (orders) => {
  //   return orders.reduce((acc, order) => {
  //     const date = order.createdTimeStamp.split(' ')[0] // Extract date portion
  //     if (!acc[date]) acc[date] = []
  //     acc[date].push(order)
  //     return acc
  //   }, {})
  // }

  // useEffect(() => {
  //   if (orders.state === 'hasValue' && orders.contents?.totalItems > 0) {
  //     setGroupedOrders(groupByDate(orders.contents.orders))
  //   } else if (orders.state === 'hasError') {
  //     console.error('Error fetching orders:', orders.contents)
  //   }
  // }, [orders])

  useEffect(() => {
    refreshOrder()
  }, [])

  // Use regular expressions to extract the required information
  const extractInfo = (str) => {
    const regex = /SCHEME:\s*([^\s]+)\s*.*ORDER NO:\s*(\d+)/

    const match = str.match(regex)

    if (match) {
      return `Scheme: ${match[1]}, Order No: ${match[2]}`
    } else {
      return 'No match found'
    }
  }

  switch (orders.state) {
    case 'loading':
      return <Loader />
    case 'hasValue':
      return (
        <>
          <div className='bg-white min-h-screen py-6 sm:py-12'>
            {orders?.contents?.status === 'SUCCESS' && orders?.contents?.orders.length > 0 && (
              <Tabs
                defaultValue='In Progress'
                value={tabValue}
                onValueChange={setTabValue}
                className='max-w-[1128px] mx-auto'
              >
                <TabsList className='mx-auto w-full'>
                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'In Progress' && 'text-primary-700 font-semibold'
                    }`}
                    value='In Progress'
                  >
                    In Progress
                    <span
                      className={`h-2 w-2 rounded-full ${
                        tabValue === 'In Progress' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>

                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'Completed' && 'text-primary-700 font-semibold'
                    }`}
                    value='Completed'
                  >
                    Completed
                    <span
                      className={`h-2 w-2 rounded-full ${
                        tabValue === 'Completed' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>

                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'Failed' && 'text-primary-700 font-semibold'
                    }`}
                    value='Failed'
                  >
                    Failed
                    <span
                      className={`h-2 w-2 rounded-full ${
                        tabValue === 'Failed' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>
                  {/* <TabsTrigger
            className={`text-primary-200 text-xl font-light flex flex-col ${
              tabValue === 'Basket' && 'text-primary-700 font-semibold'
            }`}
            value='Basket'
          >
            Basket {tabValue === 'Basket' && 'Orders'}
            <span
              className={`h-2 w-2 rounded-full  ${
                tabValue === 'Basket' ? 'bg-primary-700' : 'bg-transparent'
              }`}
            ></span>
          </TabsTrigger> */}
                </TabsList>
                <TabsContent value='In Progress' className='h-full py-12 lg:px-4'>
                  {/* MF Orders list */}

                  <div className='w-full mx-auto overflow-hidden'>
                    {orders?.contents?.orders?.filter(
                      (order) =>
                        order.currentStatus.toLowerCase() === 'payment processed successfully' ||
                        order.currentStatus.toLowerCase() === 'order placed successfully',
                    ).length > 0 ? (
                      orders?.contents?.orders
                        ?.filter(
                          (order) =>
                            order.currentStatus.toLowerCase() ===
                              'payment processed successfully' ||
                            order.currentStatus.toLowerCase() === 'order placed successfully',
                        )
                        .map((transaction, index) => (
                          <>
                            <div
                              key={index}
                              className='border-b border-gray-200 last:border-b-0 cursor-pointer px-4 lg:px-0'
                            >
                              {/* <div className='py-2 text-sm font-medium text-black-400 mb-4 mt-12 leading-5'>
                            {index}
                          </div> */}

                              {/* {orders.map((transaction, index) => ( */}
                              <div className={`p-4 flex items-center lg:items-start`}>
                                {/* <div
                              className={`flex-shrink-0 mr-6 text-[10px] flex flex-col justify-center items-center border p-2.5 rounded-[60px] relative leading-5 max-w-[58px] ${
                                transaction.buySell === 'PURCHASE'
                                  ? 'text-black-400'
                                  : 'text-black-400'
                              }`}
                            >
                              {index === 1 && (
                                <Error
                                  className='absolute -bottom-1 -right-1 h-4 w-4 text-error-500 z-2'
                                  sx={{ fontSize: '20px' }}
                                />
                              )}

                              {transaction.buySell === 'PURCHASE' ? (
                                <SouthEast sx={{ fontSize: '20px' }} />
                              ) : (
                                <NorthWest sx={{ fontSize: '20px' }} />
                              )}
                              {transaction.buySell === 'PURCHASE' ? 'Buy' : 'Sell'}
                            </div> */}
                                {/* <div className='hidden md:block mr-4'>
                                <img
                                  src={`${env.MF_LOGOS_URL}/${400034}.png`}
                                  alt=''
                                  className='h-12 w-12'
                                />
                              </div> */}

                                <div className='flex justify-between w-full'>
                                  <div
                                    className='flex justify-between items-center w-full'
                                    onClick={() => {
                                      setExpandedIndex((prevIndex) =>
                                        prevIndex === index ? null : index,
                                      )
                                    }}
                                  >
                                    <div>
                                      <div className='flex justify-between items-center gap-x-2'>
                                        <img
                                          src={`${env.MF_LOGOS_URL}/${transaction?.amcCode}.png`}
                                          className='h-12 w-12 rounded-[8px]'
                                        />
                                        <div>
                                          <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                            {transaction?.legalName
                                              ? transaction?.legalName
                                              : transaction?.schemeName}
                                          </p>

                                          {/* <p>{transaction?.orderRemarks}</p> */}
                                          <p
                                            className={`text-sm font-medium ${
                                              transaction?.currentStatus.toLowerCase() === 'failed'
                                                ? 'text-error-600'
                                                : transaction?.currentStatus.toLowerCase() ===
                                                  'allotment done'
                                                ? 'text-secondary-600'
                                                : 'text-warning-600'
                                            }`}
                                          >
                                            {transaction?.currentStatus.toUpperCase()}
                                          </p>
                                        </div>

                                        {/* <span className='f0lex-shrink-0 text-black-200 lg:hidden'>
                                        <KeyboardArrowDown sx={{ fontSize: '24px' }} />
                                      </span> */}
                                      </div>

                                      {/* <p
                                  className={`text-sm font-medium text-black-400 leading-5 ${
                                    transaction.status.includes('failed')
                                      ? 'text-red-500'
                                      : 'text-gray-500'
                                  }`}
                                  >
                                    {transaction.buySell}
                                  </p> */}

                                      {/* <div className='flex justify-between items-center text-right mt-2 lg:hidden'>
                                    <p className='text-sm font-medium text-black-400'>
                                      {transaction.qty ? transaction.qty : '#'}
                                      Units
                                    </p>
                                    <p className='font-semibold'>
                                      ₹ {transaction.amount.toLocaleString()}
                                    </p>
                                  </div> */}

                                      {/* <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                      <ChevronDown className='flex-shrink-0 w-5 h-5 text-black-200' />
                    </div> */}
                                    </div>

                                    {/* <div className='hidden lg:flex leading-5 flex-shrink-0 text-right w-[100px]'>
                                    {transaction.allotedUnits && (
                                      <p className='text-sm font-medium text-black-400'>
                                        {transaction.allotedUnits} Units
                                      </p>
                                    )}
                                  </div> */}

                                    {/* <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                                    <p className='font-semibold leading-6'>
                                      ₹ {transaction.allotedAmt ? +transaction.allotedAmt : ''}
                                    </p>
                                    <KeyboardArrowDown
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    />
                                  </div> */}
                                  </div>
                                  {/* <div
                                className={`text-sm mr-10 hidden lg:flex justify-between items-center border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                  expandedIndex === index
                                    ? 'max-h-48 opacity-100 mt-4 pt-4'
                                    : 'max-h-0 opacity-0'
                                }`}
                              >
                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    Order ID
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      FAB-01559872
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy order ID</span>
                                    </Button>
                                  </div>
                                </div>

                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    SIP Reference No.
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      123456788765432
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy SIP reference number</span>
                                    </Button>
                                  </div>
                                </div>
                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    SIP Registration No.
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      FAB-01559872
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy SIP registration number</span>
                                    </Button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              </div>
                              {/* ))} */}
                              {/* <div
                            className={`mt-4 space-y-2 text-xs flex flex-col gap-y-4 lg:hidden border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                              expandedIndex === index
                                ? 'max-h-48 opacity-100 py-3'
                                : 'max-h-0 opacity-0'
                            }`}
                          >
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>Order ID</span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500 text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy order ID</span>
                                </Button>
                              </div>
                            </div>

                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Reference No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  123456788765432
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP reference number</span>
                                </Button>
                              </div>
                            </div>
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Registration No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP registration number</span>
                                </Button>
                              </div>
                            </div>
                          </div> */}
                            </div>
                          </>
                        ))
                    ) : (
                      <Boilerplate
                        state={'You have no orders in progress at the moment.'}
                        desc={
                          'It seems like you haven’t started any new orders yet. Once you place an order, it will appear here as it progresses through the system.'
                        }
                      />
                    )}
                  </div>
                </TabsContent>

                <TabsContent value='Completed' className='h-full py-12 lg:px-4'>
                  {/* MF Orders list */}

                  <div className='w-full mx-auto overflow-hidden'>
                    {orders?.contents?.orders?.filter(
                      (order) => order.currentStatus.toLowerCase() === 'allotment done',
                    ).length > 0 ? (
                      orders?.contents?.orders
                        ?.filter((order) => order.currentStatus.toLowerCase() === 'allotment done')
                        .map((transaction, index) => (
                          <>
                            <div
                              key={index}
                              className='border-b border-gray-200 last:border-b-0 cursor-pointer px-4 lg:px-0'
                            >
                              {/* <div className='py-2 text-sm font-medium text-black-400 mb-4 mt-12 leading-5'>
                            {index}
                          </div> */}

                              {/* {orders.map((transaction, index) => ( */}
                              <div className={`p-4 flex items-center lg:items-start`}>
                                {/* <div
                              className={`flex-shrink-0 mr-6 text-[10px] flex flex-col justify-center items-center border p-2.5 rounded-[60px] relative leading-5 max-w-[58px] ${
                                transaction.buySell === 'PURCHASE'
                                  ? 'text-black-400'
                                  : 'text-black-400'
                              }`}
                            >
                              {index === 1 && (
                                <Error
                                  className='absolute -bottom-1 -right-1 h-4 w-4 text-error-500 z-2'
                                  sx={{ fontSize: '20px' }}
                                />
                              )}

                              {transaction.buySell === 'PURCHASE' ? (
                                <SouthEast sx={{ fontSize: '20px' }} />
                              ) : (
                                <NorthWest sx={{ fontSize: '20px' }} />
                              )}
                              {transaction.buySell === 'PURCHASE' ? 'Buy' : 'Sell'}
                            </div> */}
                                {/* <div className='hidden md:block mr-4'>
                                <img
                                  src={`${env.MF_LOGOS_URL}/${400034}.png`}
                                  alt=''
                                  className='h-12 w-12'
                                />
                              </div> */}

                                <div className='flex justify-between w-full'>
                                  <div
                                    className='flex justify-between items-center w-full'
                                    onClick={() => {
                                      setExpandedIndex((prevIndex) =>
                                        prevIndex === index ? null : index,
                                      )
                                    }}
                                  >
                                    <div className='flex items-center gap-x-2 w-full'>
                                      <img
                                        src={`${env.MF_LOGOS_URL}/${transaction?.amcCode}.png`}
                                        className='h-12 w-12 rounded-[8px]'
                                      />
                                      <div className='w-full'>
                                        <div>
                                          <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                            {transaction?.legalName
                                              ? transaction?.legalName.replace(/Fund.*$/, 'Fund')
                                              : transaction?.schemeName.replace(
                                                  /Fund.*$/,
                                                  'Fund',
                                                )}{' '}
                                          </p>
                                          {/* <p>{transaction?.orderRemarks}</p> */}
                                          <p
                                            className={`text-sm font-medium ${
                                              transaction?.currentStatus.toLowerCase() === 'failed'
                                                ? 'text-error-600'
                                                : transaction?.currentStatus.toLowerCase() ===
                                                  'allotment done'
                                                ? 'text-secondary-600'
                                                : 'text-warning-600'
                                            }`}
                                          >
                                            {transaction?.currentStatus.toUpperCase()}
                                          </p>
                                        </div>
                                        <div className='flex lg:hidden justify-between items-center'>
                                          <div className='flex leading-5 flex-shrink-0 text-right'>
                                            {transaction.allotedUnits && (
                                              <p className='text-sm font-medium text-black-400'>
                                                {toCurrency(transaction?.allotedUnits)} Units
                                              </p>
                                            )}
                                          </div>

                                          <div className='flex justify-end items-center gap-x-1'>
                                            {transaction.allotedAmt && (
                                              <p className='font-semibold leading-6'>
                                                ₹{' '}
                                                {transaction.allotedAmt
                                                  ? +transaction.allotedAmt
                                                  : ''}
                                              </p>
                                            )}
                                            {/* <KeyboardArrowDown
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    /> */}
                                          </div>
                                        </div>
                                      </div>

                                      {/* <span className='f0lex-shrink-0 text-black-200 lg:hidden'>
                                        <KeyboardArrowDown sx={{ fontSize: '24px' }} />
                                      </span> */}
                                    </div>

                                    {/* <p
                                  className={`text-sm font-medium text-black-400 leading-5 ${
                                    transaction.status.includes('failed')
                                      ? 'text-red-500'
                                      : 'text-gray-500'
                                  }`}
                                  >
                                    {transaction.buySell}
                                  </p> */}

                                    {/* <div className='flex justify-between items-center text-right mt-2 lg:hidden'>
                                    <p className='text-sm font-medium text-black-400'>
                                      {transaction.qty ? transaction.qty : '#'}
                                      Units
                                    </p>
                                    <p className='font-semibold'>
                                      ₹ {transaction.amount.toLocaleString()}
                                    </p>
                                  </div> */}

                                    {/* <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                      <ChevronDown className='flex-shrink-0 w-5 h-5 text-black-200' />
                    </div> */}

                                    <div className='hidden md:flex justify-center items-center'>
                                      <div className='hidden lg:flex leading-5 flex-shrink-0 text-right w-[100px]'>
                                        {transaction.allotedUnits && (
                                          <p className='text-sm font-medium text-black-400'>
                                            {toCurrency(transaction?.allotedUnits)} Units
                                          </p>
                                        )}
                                      </div>

                                      <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                                        {transaction.allotedAmt && (
                                          <p className='font-semibold leading-6'>
                                            ₹{' '}
                                            {transaction.allotedAmt ? +transaction.allotedAmt : ''}
                                          </p>
                                        )}
                                        {/* <KeyboardArrowDown
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    /> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div
                              className={`text-sm mr-10 hidden lg:flex justify-between items-center border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                expandedIndex === index
                                  ? 'max-h-48 opacity-100 mt-4 pt-4'
                                  : 'max-h-0 opacity-0'
                              }`}
                            >
                              <div className='flex items-center justify-between gap-x-2 leading-5'>
                                <span className='font-medium text-sm text-black-400'>Order ID</span>
                                <div className='flex items-center space-x-2'>
                                  <span className='font-semibold text-black-500 text-sm'>
                                    FAB-01559872
                                  </span>
                                  <Button size='simple' variant='ghost'>
                                    <ContentCopy
                                      className='text-primary-500'
                                      sx={{ fontSize: '16px' }}
                                    />
                                    <span className='sr-only'>Copy order ID</span>
                                  </Button>
                                </div>
                              </div>

                              <div className='flex items-center justify-between gap-x-2 leading-5'>
                                <span className='font-medium text-sm text-black-400'>
                                  SIP Reference No.
                                </span>
                                <div className='flex items-center space-x-2'>
                                  <span className='font-semibold text-black-500 text-sm'>
                                    123456788765432
                                  </span>
                                  <Button size='simple' variant='ghost'>
                                    <ContentCopy
                                      className='text-primary-500'
                                      sx={{ fontSize: '16px' }}
                                    />
                                    <span className='sr-only'>Copy SIP reference number</span>
                                  </Button>
                                </div>
                              </div>
                              <div className='flex items-center justify-between gap-x-2 leading-5'>
                                <span className='font-medium text-sm text-black-400'>
                                  SIP Registration No.
                                </span>
                                <div className='flex items-center space-x-2'>
                                  <span className='font-semibold text-black-500 text-sm'>
                                    FAB-01559872
                                  </span>
                                  <Button size='simple' variant='ghost'>
                                    <ContentCopy
                                      className='text-primary-500'
                                      sx={{ fontSize: '16px' }}
                                    />
                                    <span className='sr-only'>Copy SIP registration number</span>
                                  </Button>
                                </div>
                              </div>
                            </div> */}
                              {/* ))} */}
                              {/* <div
                            className={`mt-4 space-y-2 text-xs flex flex-col gap-y-4 lg:hidden border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                              expandedIndex === index
                                ? 'max-h-48 opacity-100 py-3'
                                : 'max-h-0 opacity-0'
                            }`}
                          >
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>Order ID</span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500 text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy order ID</span>
                                </Button>
                              </div>
                            </div>

                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Reference No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  123456788765432
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP reference number</span>
                                </Button>
                              </div>
                            </div>
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Registration No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP registration number</span>
                                </Button>
                              </div>
                            </div>
                          </div> */}
                            </div>
                          </>
                        ))
                    ) : (
                      <Boilerplate
                        state={'No completed orders found.'}
                        desc={
                          'There are no completed orders in your account. Completed orders will be listed here once processed successfully.'
                        }
                      />
                    )}
                  </div>
                </TabsContent>

                <TabsContent value='Failed' className='h-full py-12 lg:px-4'>
                  {/* MF Orders list */}

                  <div className='w-full mx-auto overflow-hidden'>
                    {orders?.contents?.orders?.filter(
                      (order) => order.currentStatus.toLowerCase() === 'failed',
                    ).length > 0 ? (
                      orders?.contents?.orders
                        ?.filter((order) => order.currentStatus.toLowerCase() === 'failed')
                        .map((transaction, index) => (
                          <>
                            <div
                              key={index}
                              className='border-b border-gray-200 last:border-b-0 cursor-pointer px-4 lg:px-0'
                            >
                              {/* <div className='py-2 text-sm font-medium text-black-400 mb-4 mt-12 leading-5'>
                            {index}
                          </div> */}

                              {/* {orders.map((transaction, index) => ( */}
                              <div className={`p-4 flex items-center lg:items-start`}>
                                {/* <div
                              className={`flex-shrink-0 mr-6 text-[10px] flex flex-col justify-center items-center border p-2.5 rounded-[60px] relative leading-5 max-w-[58px] ${
                                transaction.buySell === 'PURCHASE'
                                  ? 'text-black-400'
                                  : 'text-black-400'
                              }`}
                            >
                              {index === 1 && (
                                <Error
                                  className='absolute -bottom-1 -right-1 h-4 w-4 text-error-500 z-2'
                                  sx={{ fontSize: '20px' }}
                                />
                              )}

                              {transaction.buySell === 'PURCHASE' ? (
                                <SouthEast sx={{ fontSize: '20px' }} />
                              ) : (
                                <NorthWest sx={{ fontSize: '20px' }} />
                              )}
                              {transaction.buySell === 'PURCHASE' ? 'Buy' : 'Sell'}
                            </div> */}
                                {/* <div className='hidden md:block mr-4'>
                                <img
                                  src={`${env.MF_LOGOS_URL}/${400034}.png`}
                                  alt=''
                                  className='h-12 w-12'
                                />
                              </div> */}

                                <div className='flex justify-between w-full'>
                                  <div
                                    className='flex justify-between items-center w-full'
                                    onClick={() => {
                                      setExpandedIndex((prevIndex) =>
                                        prevIndex === index ? null : index,
                                      )
                                    }}
                                  >
                                    <div>
                                      <div className='flex justify-between items-center gap-x-2'>
                                        <img
                                          src={`${env.MF_LOGOS_URL}/${transaction?.amcCode}.png`}
                                          className='h-12 w-12 rounded-[8px]'
                                        />
                                        <div>
                                          <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                            {transaction?.legalName
                                              ? transaction?.legalName
                                              : transaction?.schemeName}
                                          </p>

                                          {/* <p>{transaction?.orderRemarks}</p> */}
                                          <p
                                            className={`text-sm font-medium ${
                                              transaction?.currentStatus.toLowerCase() === 'failed'
                                                ? 'text-error-600'
                                                : transaction?.currentStatus.toLowerCase() ===
                                                  'allotment done'
                                                ? 'text-secondary-600'
                                                : 'text-warning-600'
                                            }`}
                                          >
                                            {transaction?.currentStatus.toUpperCase()}
                                          </p>
                                        </div>

                                        {/* <span className='f0lex-shrink-0 text-black-200 lg:hidden'>
                                        <KeyboardArrowDown sx={{ fontSize: '24px' }} />
                                      </span> */}
                                      </div>

                                      {/* <p
                                  className={`text-sm font-medium text-black-400 leading-5 ${
                                    transaction.status.includes('failed')
                                      ? 'text-red-500'
                                      : 'text-gray-500'
                                  }`}
                                  >
                                    {transaction.buySell}
                                  </p> */}

                                      {/* <div className='flex justify-between items-center text-right mt-2 lg:hidden'>
                                    <p className='text-sm font-medium text-black-400'>
                                      {transaction.qty ? transaction.qty : '#'}
                                      Units
                                    </p>
                                    <p className='font-semibold'>
                                      ₹ {transaction.amount.toLocaleString()}
                                    </p>
                                  </div> */}

                                      {/* <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                      <ChevronDown className='flex-shrink-0 w-5 h-5 text-black-200' />
                    </div> */}
                                    </div>

                                    {/* <div className='hidden lg:flex leading-5 flex-shrink-0 text-right w-[100px]'>
                                    {transaction.allotedUnits && (
                                      <p className='text-sm font-medium text-black-400'>
                                        {transaction.allotedUnits} Units
                                      </p>
                                    )}
                                  </div> */}
                                    {/* 
                                  <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                                    <p className='font-semibold leading-6'>
                                      ₹ {transaction.allotedAmt ? +transaction.allotedAmt : ''}
                                    </p>
                                    <KeyboardArrowDown
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    />
                                  </div> */}
                                  </div>
                                  {/* <div
                                className={`text-sm mr-10 hidden lg:flex justify-between items-center border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                  expandedIndex === index
                                    ? 'max-h-48 opacity-100 mt-4 pt-4'
                                    : 'max-h-0 opacity-0'
                                }`}
                              >
                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    Order ID
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      FAB-01559872
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy order ID</span>
                                    </Button>
                                  </div>
                                </div>

                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    SIP Reference No.
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      123456788765432
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy SIP reference number</span>
                                    </Button>
                                  </div>
                                </div>
                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    SIP Registration No.
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      FAB-01559872
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy SIP registration number</span>
                                    </Button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              </div>
                              {/* ))} */}
                              {/* <div
                            className={`mt-4 space-y-2 text-xs flex flex-col gap-y-4 lg:hidden border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                              expandedIndex === index
                                ? 'max-h-48 opacity-100 py-3'
                                : 'max-h-0 opacity-0'
                            }`}
                          >
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>Order ID</span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500 text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy order ID</span>
                                </Button>
                              </div>
                            </div>

                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Reference No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  123456788765432
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP reference number</span>
                                </Button>
                              </div>
                            </div>
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Registration No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP registration number</span>
                                </Button>
                              </div>
                            </div>
                          </div> */}
                            </div>
                          </>
                        ))
                    ) : (
                      <Boilerplate
                        state={'No failed orders to display.'}
                        desc={
                          'Good news! You don’t have any failed orders. If an issue occurs with an order, it will be shown here for your reference.'
                        }
                      />
                    )}
                  </div>
                </TabsContent>
                {/* <TabsContent value='Basket'>b</TabsContent> */}
              </Tabs>
            )}

            {orders?.contents?.status === 'SUCCESS' && orders?.contents?.orders.length === 0 && (
              <div className='bg-white flex items-center justify-center'>
                <Card className='w-full max-w-sm text-center border-none shadow-none'>
                  <CardHeader>
                    <PiggyBank className='w-12 h-12 text-primary mx-auto mb-4' />
                    <CardTitle className='text-xl'>You have not made an order yet</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className='text-muted-foreground mb-6'>
                      Start your investment journey by creating your first Systematic Investment
                      Plan.
                    </p>
                    <Link to='/dashboard/mutual-funds'>
                      <Button className='w-full'>
                        <Plus className='mr-2 h-4 w-4' /> Create Order
                      </Button>
                    </Link>
                  </CardContent>
                </Card>
              </div>
            )}

            {orders?.contents?.status === 'FAILURE' && (
              <div className='bg-white flex items-center justify-center'>
                <Card className='w-full max-w-sm text-center border-none shadow-none font-DMSans'>
                  <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
                    <img
                      className='h-14 w-14'
                      src={`${env.SVGS}/error-icon.svg`}
                      alt='Error-Icon'
                    />
                    <CardTitle className='font-semibold text-base'>
                      Oops! Something Went Wrong
                    </CardTitle>
                  </CardHeader>
                  <CardContent className='flex flex-col gap-y-6'>
                    <p className='text-sm leading-5 text-black-400 mb-6'>
                      We're sorry, but it seems there was an issue connecting to our server. Please
                      check your internet connection or try refreshing the page. If the problem
                      persists, try again later.
                    </p>

                    <div className='flex gap-x-2 justify-between'>
                      <Button
                        className='w-full'
                        variant='fabitsWhite'
                        onClick={() => window.location.reload()}
                      >
                        <Refresh className='mr-2 h-4 w-4 text-black-300' /> Refresh
                      </Button>

                      <Link className='flex-1' to='/dashboard/mutual-funds'>
                        <Button className='w-full'>
                          View Funds
                          <MoveRight className='ml-2 h-4 w-4' />
                        </Button>
                      </Link>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </>
      )

    case 'hasError':
      return <div>Error</div>

    default:
      return null
  }
}

export const Boilerplate = ({ state, desc }) => {
  return (
    <div className='h-3/5 flex items-center justify-center p-4'>
      <Card className='w-full max-w-sm text-center border-none shadow-none'>
        <CardHeader>
          {/* <PiggyBank className='w-12 h-12 text-primary mx-auto mb-4' /> */}
          <div className='w-20 h-20 mx-auto mb-4 flex justify-center items-center'>
            <img src={`${env.SVGS}/error-icon.svg`} />
          </div>
          <CardTitle className='text-xl'>{state}</CardTitle>
        </CardHeader>
        <CardContent>
          <p className='text-muted-foreground mb-6'>{desc}</p>
          {/* <Link to='/dashboard/mutual-funds'>
            <Button className='w-full'>
              <Plus className='mr-2 h-4 w-4' /> Create SIP
            </Button>
          </Link> */}
        </CardContent>
      </Card>
    </div>
  )
}

export default MFOrders
