import goldbonds from '../assets/Navbar/goldbonds.svg'
import ipo from '../assets/Navbar/IPO.svg'
import wallet from '../assets/Navbar/Wallet.svg'
import insurance from '../assets/Navbar/insurance.svg'
import orders from '../assets/Navbar/orders.svg'
import sip from '../assets/Navbar/sip.svg'
import {
  AddCircle,
  AddCircleOutline,
  AdminPanelSettings,
  AdminPanelSettingsOutlined,
  HomeOutlined,
  HomeRounded,
  TravelExplore,
  TravelExploreOutlined,
  TrendingUpOutlined,
  TrendingUpRounded,
  WidgetsOutlined,
  WidgetsRounded,
} from '@mui/icons-material'

export const navbarData = [
  {
    id: 1,
    icon: <HomeOutlined />,
    iconIsActive: <HomeRounded />,
    link: '/dashboard',
    title: 'Home',
  },
  {
    id: 2,
    icon: <TravelExploreOutlined />,
    iconIsActive: <TravelExplore />,
    // link: '/dashboard/theme',
    title: 'Discover',
  },
  {
    id: 3,
    icon: 'add_circle',
    // iconIsActive: <HomeRounded />,
    link: '/dashboard/plans',
    title: 'Goals',
  },

  {
    id: 4,
    icon: <TrendingUpOutlined />,
    iconIsActive: <TrendingUpRounded />,
    link: '/dashboard/portfolio',
    title: 'Portfolio',
  },
  {
    id: 5,
    icon: <WidgetsOutlined />,
    iconIsActive: <WidgetsRounded />,
    link: '',
    title: 'More',
  },
  {
    id: 6,
    icon: goldbonds,
    link: '/dashboard/goldbonds',
    title: 'Gold Bonds',
  },
  {
    id: 7,
    icon: ipo,
    link: '/dashboard/ipo',
    title: 'IPO',
  },
  {
    id: 8,
    icon: insurance,
    link: '/dashboard/insurance',
    title: 'Insurance',
  },
  {
    id: 9,
    icon: orders,
    link: '/dashboard/activities',
    title: 'Orders',
  },
  {
    id: 10,
    icon: sip,
    link: '/dashboard/sips',
    title: 'SIPs',
  },
  {
    id: 11,
    icon: wallet,
    link: '/dashboard/funds',
    title: 'Wallet',
  },
]

export const navTitles = [
  {
    linkName: 'Home',
    linkPath: '/dashboard',
    pathName: 'home',
    highlightUrls: ['/dashboard'],
  },

  {
    linkName: 'Plan a Goal',
    linkPath: '/dashboard/plans',
    pathName: 'plan',

    highlightUrls: [
      '/dashboard/plans',
      '/dashboard/vacationplan',
      '/dashboard/emergencyplan',
      '/dashboard/weddingplan',
      '/dashboard/purchaseplan',
      '/dashboard/retirementplan',
      '/dashboard/educationplan',
      '/dashboard/invest',
    ],
  },
  {
    linkName: 'My Investments',
    linkPath: '/dashboard/portfolio',
    pathName: 'portfolio',
    iconName: 'real_estate_agent',
    highlightUrls: ['/dashboard/portfolio', '/dashboard/holdings'],
  },
  {
    linkName: 'SIPs',
    linkPath: '/dashboard/sips',
    pathName: 'sips',

    highlightUrls: ['/dashboard/sips', '/dashboard/e-mandate'],
  },
  {
    linkName: 'Orders',
    linkPath: '/dashboard/activities',
    pathName: 'activities',

    highlightUrls: ['/dashboard/activities'],
  },
  {
    linkName: 'Account Balance',
    linkPath: '/dashboard/funds',
    pathName: 'funds',
    iconName: 'account_balance_wallet',
    highlightUrls: ['/dashboard/funds'],
  },

  {
    linkName: 'Discover Top funds',
    linkPath: '/dashboard/theme',
    pathName: 'theme',
    iconName: 'bubble_chart',
    highlightUrls: ['/dashboard/theme', '/dashboard/theme-details'],
  },
  {
    linkName: 'IPO',
    linkPath: '/dashboard/ipo',
    pathName: 'ipo',
    iconName: 'add_business',
    highlightUrls: ['/dashboard/ipo'],
  },
  {
    linkName: 'Gold Bonds',
    linkPath: '/dashboard/goldbonds',
    pathName: 'gold',
    iconName: 'stacks',
    highlightUrls: ['/dashboard/goldbonds'],
  },

  {
    linkName: 'Insurance',
    linkPath: '/dashboard/insurance',
    pathName: 'insurance',
    iconName: 'admin_panel_settings',
    highlightUrls: ['/dashboard/insurance'],
  },

  {
    linkName: 'Support',
    linkPath: '/dashboard/support',
    pathName: 'insurance',
    iconName: 'admin_panel_settings',
    highlightUrls: ['/dashboard/insurance'],
  },

  {
    linkName: 'Refer & Rewards',
    linkPath: '/dashboard/refer',
    pathName: 'insurance',
    iconName: 'admin_panel_settings',
    highlightUrls: ['/dashboard/insurance'],
  },

  {
    linkName: 'Profile',
    linkPath: '/dashboard/profile',
    pathName: 'insurance',
    iconName: 'admin_panel_settings',
    highlightUrls: ['/dashboard/insurance'],
  },
  {
    linkName: 'Plan a Goal',
    linkPath: '/dashboard/homepurchase',
    pathName: 'insurance',
    iconName: 'admin_panel_settings',
    highlightUrls: ['/dashboard/insurance'],
  },
]

export const navbarTitles = [
  {
    id: 1,
    icon: 'home',
    link: '/dashboard',
    title: 'Home',
  },
  {
    id: 2,
    icon: 'bubble_chart',
    link: '/dashboard/theme',
    title: 'Discover Top Funds',
  },
  {
    id: 3,
    icon: 'add_circle',
    link: '/dashboard/plans',
    title: 'Goals',
  },

  {
    id: 4,
    icon: 'real_estate_agent',
    link: '/dashboard/portfolio',
    title: 'Portfolio',
  },
  {
    id: 5,
    icon: 'widgets',
    link: '',
    title: 'More',
  },
  {
    id: 6,
    icon: '/Navbar/goldbonds.svg',
    link: '/dashboard/goldbonds',
    title: 'Gold Bonds',
  },
  {
    id: 7,
    icon: '/Navbar/ipo.svg',
    link: '/dashboard/ipo',
    title: 'IPO',
  },
  {
    id: 8,
    icon: '/Navbar/insurance.svg',
    link: '/dashboard/insurance',
    title: 'Insurance',
  },
  {
    id: 9,
    icon: '/Navbar/orders.svg',
    link: '/dashboard/activities',
    title: 'Orders',
  },
  {
    id: 10,
    icon: '/Navbar/sip.svg',
    link: '/dashboard/sips',
    title: 'SIPs',
  },
  {
    id: 11,
    icon: '/Navbar/wallet.svg',
    link: '/dashboard/funds',
    title: 'Wallet',
  },
]

export const navbarDataNonDemat = [
  {
    id: 1,
    icon: <HomeOutlined />,
    iconIsActive: <HomeRounded />,
    link: '/dashboard',
    title: 'Home',
  },
  {
    id: 2,
    icon: <TravelExploreOutlined />,
    iconIsActive: <TravelExplore />,
    link: '/dashboard/theme',
    title: 'Discover',
  },
  {
    id: 3,
    icon: 'handshake',
    link: 'https://onboarding.fabits.com',
    title: 'KYC',
  },
  {
    id: 4,
    icon: <AddCircleOutline />,
    iconIsActive: <AddCircle />,
    link: '/dashboard/plans',
    title: 'Goals',
  },

  {
    id: 5,
    icon: <AdminPanelSettingsOutlined />,
    iconIsActive: <AdminPanelSettings />,
    link: '/dashboard/insurance',
    title: 'Insurance',
  },
]
