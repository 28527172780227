/* eslint-disable */
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from '../ui/menubar'
import { getUserDetails, isDematUser, removeToken } from '../../utils/AuthUtil'
import { Avatar, AvatarFallback } from '../ui/avatar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userDetailsState } from '../../Pages/LoginState'
import NavigationStore from '../../store/NavigationStore'
import { useContext, useEffect, useState } from 'react'
import {
  NavigationMenu,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '../ui/navigation-menu'
import { ChevronLeft, Logout, Search } from '@mui/icons-material'
import useMediaQuery from '../../hooks/useMediaQuery'
import twitter from '../../assets/Twitter.svg'
import YouTube from '../../assets/Youtube.svg'
import LinkedIn from '../../assets/Linkedin.svg'
import Instagram from '../../assets/Instagram.svg'
import env from '../../env/env'
import { Button } from '../ui/button'
import { X } from 'lucide-react'
import { Input } from '../ui/input'
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp'
import { DrawerContext } from '../../Provider/DrawerContext'
import { navbarData, navbarDataNonDemat } from '../../../src/constants/navbar'
import goals from '../../assets/Navbar/goals.svg'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '../ui/sheet'
import ShoppingBag from '../../assets/Navbar/ShoppingBag.svg'
import ArrowRight from '../../assets/Navbar/ArrowRight.svg'
import Timeline from '../../assets/Navbar/Timeline.svg'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import KYC from '../../assets/Navbar/kyc.svg'
import BottomNavbar from '../BottomNavbar'

const socials = [
  {
    id: 1,
    icon: YouTube,
    linkTo: 'https://www.youtube.com/@fabits.social',
  },
  {
    id: 2,
    icon: LinkedIn,
    linkTo: 'https://www.linkedin.com/company/fabits/',
  },
  {
    id: 3,
    icon: twitter,
    linkTo: 'https://x.com/fabitssocial',
  },
  {
    id: 4,
    icon: Instagram,
    linkTo: 'https://www.instagram.com/fabits.social',
  },
]

const MFNavbar = ({ handleSearchOpen }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [userDetails, setUserDetails] = useRecoilState(userDetailsState)
  const navState = useRecoilValue(NavigationStore)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { isDrawerOpen } = useContext(DrawerContext)
  const [showNavbar, setShowNavbar] = useState(true)
  const [openMore, setOpenMore] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [openDiscover, setOpenDiscover] = useState(false)

  useEffect(() => {
    if (openDiscover || showMenu) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [openDiscover, showMenu])

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      setScrollPosition(position)
      setIsTransitioning(position > 300 && position < 404)
    }

    window.addEventListener('scroll', handleScroll)

    setUserDetails(getUserDetails())

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleLogout = () => {
    removeToken()
    navigate('/login')
  }

  const getTextStyle = () => {
    if (scrollPosition < 300) return 'text-primary-100'
    if (scrollPosition > 300) return 'text-black-400'
    return `text-primary-100 transition-colors duration-300 ${
      isTransitioning ? 'text-opacity-50' : ''
    }`
  }

  return (
    <>
      {isDesktop ? (
        <div
          className={`w-full h-12 z-[999999] transition-all duration-200 ${
            scrollPosition > 100 ? 'fixed' : 'sticky'
          } top-0 left-0 backdrop-blur bg-opacity-80`}
        >
          <div className='max-w-[1128px] flex justify-between items-center mx-auto h-full'>
            <div>
              <img
                src={scrollPosition < 404 ? `${env.SVGS}/logo2.svg` : `${env.SVGS}/logo.svg`}
                alt='Fabits'
              />
            </div>

            <div className='flex gap-x-5 h-full'>
              {navState.slice(0, 4).map((link) => (
                <Link
                  key={link.pathName}
                  to={link.linkPath}
                  className={`${
                    link.highlightUrls.includes(location.pathname)
                      ? scrollPosition < 404
                        ? 'text-white font-semibold'
                        : 'text-primary-500'
                      : getTextStyle()
                  } flex items-center font-medium text-sm no-underline transition-colors duration-300`}
                >
                  {link.linkName}
                </Link>
              ))}

              {/* More dropdown remains the same */}
              <NavigationMenu>
                <NavigationMenuList>
                  <NavigationMenuItem>
                    <NavigationMenuTrigger
                      className={`p-0 bg-transparent ${
                        scrollPosition < 404
                          ? getTextStyle()
                          : 'text-black-400 hover:text-black-300'
                      } `}
                    >
                      More
                    </NavigationMenuTrigger>
                    <NavigationMenuContent className='p-3 flex flex-col gap-y-2 md:w-[150px]'>
                      {navState.slice(4, navState.length).map((link) => (
                        <NavigationMenuLink className='no-underline' key={link.pathName}>
                          <Link
                            to={link.linkPath}
                            className={`${
                              link.highlightUrls.includes(location.pathname)
                                ? ' text-primary-500'
                                : 'text-black-400 hover:text-primary-400'
                            } flex justify-between items-center font-medium text-sm no-underline`}
                          >
                            {link.linkName}

                            {/* <ChevronRight className='h-4 w-4' /> */}
                          </Link>
                        </NavigationMenuLink>
                      ))}
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
            </div>

            <div className='flex items-center'>
              {/* Notification icon */}
              <div className='flex items-center mr-6 cursor-pointer'>
                <NotificationsNoneSharpIcon
                  id='web-inbox-placeholder'
                  sx={{ width: '20px', height: '20px' }}
                  className={`${
                    scrollPosition < 404 ? 'text-white' : 'text-black-400'
                  } transition-colors duration-300`}
                />
              </div>

              {/* User name */}
              <p
                className={`capitalize text-sm font-semibold mr-4 ${
                  scrollPosition < 404 ? 'text-white' : 'text-black-400'
                } transition-colors duration-300`}
              >
                {userDetails.firstName} {userDetails.lastName}
              </p>

              {/* Avatar and menu remain the same */}
              <Menubar>
                <MenubarMenu className='bg-transparent'>
                  <MenubarTrigger onClick={() => setShowMenu(true)}>
                    <Avatar className='cursor-pointer h-7 w-7'>
                      {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                      <AvatarFallback className='bg-primary-50 text-black-400'>
                        {userDetails?.firstName?.slice(0, 1)}
                      </AvatarFallback>
                    </Avatar>
                  </MenubarTrigger>

                  {isDematUser() ? (
                    showMenu && !isDesktop ? (
                      <MenubarContent className='relative top-[-65px] w-[100vw] h-[100vh] z-[9999] bg-white flex flex-col gap-y-[2px] p-0'>
                        <MenubarItem className='flex justify-between py-4 px-5'>
                          <p onClick={() => setShowMenu(false)}>
                            <ChevronLeft /> Profile
                          </p>
                          <div className='flex lg:hidden justify-center items-center gap-x-4'>
                            {!isDesktop && (
                              <NotificationsNoneSharpIcon
                                id='web-inbox-placeholder'
                                sx={{ width: '24px', height: '24px' }}
                                className='text-black-400'
                                onClick={() => setShowMenu(false)}
                              />
                            )}
                          </div>
                        </MenubarItem>
                        <MenubarItem className='flex justify-center py-4 px-5'>
                          <div className='grid justify-items-center'>
                            <div className='h-[72px] w-[72px] aspect-square bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                              {userDetails?.firstName?.slice(0, 1)}
                            </div>
                            <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                              {userDetails.firstName} {userDetails.lastName}
                            </div>
                          </div>
                        </MenubarItem>
                        {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                        <div className='px-5 grid gap-4 my-4'>
                          <div>
                            <p className='text-primary-200 font-DMSans font-medium text-[10px] leading-3'>
                              ACCOUNT
                            </p>

                            <Link to='/dashboard/profile'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu(false)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  person
                                </span>
                                <p>Account Details</p>
                              </MenubarItem>
                            </Link>

                            <Link className='no-underline text-black-500' to='/dashboard/refer'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl'>
                                  emoji_events
                                </span>
                                {/* <ShareOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: 'black' }}
                              /> */}
                                <p>Refer & Rewards</p>
                              </MenubarItem>
                            </Link>

                            {/* <Link to='/dashboard/myplan'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                license
                              </span>
                              <p>Fabits Subscription</p>
                            </MenubarItem>
                          </Link> */}

                            <Link to='/dashboard/funds'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu(false)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  account_balance_wallet
                                </span>
                                <p>Wallet</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              INVESTMENTS
                            </p>

                            <Link to='/dashboard/portfolio'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu(false)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  local_mall
                                </span>
                                <p>Individual Holdings</p>
                              </MenubarItem>
                            </Link>

                            <Link to='/dashboard/sips'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu(false)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  currency_exchange
                                </span>
                                <p>SIPs</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              CONNECT
                            </p>

                            <Link to='/dashboard/support'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu(false)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  support_agent
                                </span>
                                <p>Support</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div className='flex justify-center'>
                            <MenubarItem className='justify-between w-44 p-0'>
                              {socials.map((social) => {
                                return (
                                  <div
                                    key={social.id}
                                    // className='bg-black-500 h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                    // onClick={() => setShowMenu(false)}
                                  >
                                    <a
                                      href={social.linkTo}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      <img src={social.icon} alt={social.id} />
                                    </a>
                                  </div>
                                )
                              })}
                            </MenubarItem>
                          </div>
                        </div>

                        <div
                          className='cursor-pointer px-5 my-4  flex justify-center'
                          onClick={() => handleLogout()}
                        >
                          <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500 hover:bg-error-200 '>
                            Logout
                          </div>
                        </div>
                      </MenubarContent>
                    ) : (
                      <MenubarContent
                        className='rounded-[20px] flex flex-col gap-y-[2px] w-[280px] p-0'
                        align='center'
                      >
                        <MenubarItem className='justify-between bg-[#F4F7FE] py-4 px-5'>
                          <div className='flex gap-x-4 items-center'>
                            <Avatar className='h-7 w-7'>
                              {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                              <AvatarFallback className='bg-primary-100 text-black-400'>
                                {userDetails?.firstName?.slice(0, 1)}
                              </AvatarFallback>
                            </Avatar>

                            <div className='text-black-500 font-DMSans font-semibold text-base'>
                              {userDetails.firstName}
                            </div>
                          </div>
                          <div className='cursor-pointer'>
                            <Logout
                              onClick={handleLogout}
                              sx={{ color: '#ff6666', fontSize: 20 }}
                            />
                          </div>
                        </MenubarItem>
                        {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                        <div className='px-5 grid gap-4 my-4'>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              ACCOUNT
                            </p>

                            <Link to='/dashboard/profile'>
                              <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  person
                                </span>
                                <p>Account Details</p>
                              </MenubarItem>
                            </Link>

                            <Link className='no-underline text-black-500' to='/dashboard/refer'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl'>
                                  emoji_events
                                </span>
                                {/* <ShareOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: 'black' }}
                              /> */}
                                <p>Refer & Rewards</p>
                              </MenubarItem>
                            </Link>

                            {/* <Link to='/dashboard/myplan'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                license
                              </span>
                              <p>Fabits Subscription</p>
                            </MenubarItem>
                          </Link> */}

                            <Link to='/dashboard/funds'>
                              <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  account_balance_wallet
                                </span>
                                <p>Wallet</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              INVESTMENTS
                            </p>

                            <Link to='/dashboard/portfolio'>
                              <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  local_mall
                                </span>
                                <p>Individual Holdings</p>
                              </MenubarItem>
                            </Link>

                            <Link to='/dashboard/sips'>
                              <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  currency_exchange
                                </span>
                                <p>SIPs</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              CONNECT
                            </p>

                            <Link to='/dashboard/support'>
                              <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  support_agent
                                </span>
                                <p>Support</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div className='flex justify-center'>
                            <MenubarItem className='justify-between w-36 p-0'>
                              {socials.map((social) => {
                                return (
                                  <div
                                    key={social.id}
                                    className='h-6 w-6 rounded-full flex justify-center items-center cursor-pointer'
                                  >
                                    <a
                                      href={social.linkTo}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      <img src={social.icon} alt={social.id} />
                                    </a>
                                  </div>
                                )
                              })}
                            </MenubarItem>
                          </div>
                        </div>
                      </MenubarContent>
                    )
                  ) : showMenu && !isDesktop ? (
                    <MenubarContent className='relative top-[-65px] w-[100vw] h-[100vh] z-[9999] bg-white flex flex-col gap-y-[2px] p-0'>
                      <MenubarItem className='flex justify-between py-4 px-5'>
                        <p onClick={() => setShowMenu(false)}>
                          <ChevronLeft /> Profile
                        </p>
                        <div className='flex lg:hidden justify-center items-center gap-x-4'>
                          {!isDesktop && (
                            <NotificationsNoneSharpIcon
                              id='web-inbox-placeholder'
                              sx={{ width: '24px', height: '24px' }}
                              className='text-black-400'
                              onClick={() => setShowMenu(false)}
                            />
                          )}
                        </div>
                      </MenubarItem>
                      <MenubarItem className='flex justify-center py-4 px-5'>
                        <div className='grid justify-items-center'>
                          <div className='h-[72px] w-[72px] aspect-square bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                            {userDetails?.firstName?.slice(0, 1)}
                          </div>
                          <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                            {userDetails.firstName} {userDetails.lastName}
                          </div>
                        </div>
                      </MenubarItem>
                      {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                      <div className='px-5 grid gap-4 my-4 no-underline'>
                        <Link className='no-underline text-black-500' to='/dashboard/refer'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu((prev) => !prev)}
                          >
                            <span className='material-symbols-outlined text-xl'>emoji_events</span>
                            {/* <ShareOutlined
                            sx={{ fontSize: '20px', lineHeight: '28px', color: 'black' }}
                          /> */}
                            <p>Refer & Rewards</p>
                          </MenubarItem>
                        </Link>

                        <div>
                          <p className='text-primary-200 font-medium text-[10px] leading-3'>
                            CONNECT
                          </p>

                          <Link to='/dashboard/support'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                support_agent
                              </span>
                              <p>Support</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div className='flex justify-center'>
                          <MenubarItem className='justify-between w-44 p-0'>
                            {socials.map((social) => {
                              return (
                                <div
                                  key={social.id}
                                  // className='bg-black-500 h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                  // onClick={() => setShowMenu(false)}
                                >
                                  <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                    <img src={social.icon} alt={social.id} />
                                  </a>
                                </div>
                              )
                            })}
                          </MenubarItem>
                        </div>
                      </div>

                      <div
                        className='cursor-pointer px-5 my-4  flex justify-center'
                        onClick={() => handleLogout()}
                      >
                        <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500 hover:bg-error-200 '>
                          Logout
                        </div>
                      </div>
                    </MenubarContent>
                  ) : (
                    <MenubarContent
                      className='rounded-[20px] flex flex-col gap-y-[2px] w-[280px] p-0'
                      align='center'
                    >
                      <MenubarItem className='justify-between bg-[#F4F7FE] py-4 px-5'>
                        <div className='flex gap-x-4 items-center'>
                          <Avatar className='h-7 w-7'>
                            {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                            <AvatarFallback className='bg-primary-100 text-black-400'>
                              {userDetails?.firstName?.slice(0, 1)}
                            </AvatarFallback>
                          </Avatar>

                          <div className='text-black-500 font-DMSans font-semibold text-base'>
                            {userDetails.firstName}
                          </div>
                        </div>
                        <div className='cursor-pointer'>
                          <Logout onClick={handleLogout} sx={{ color: '#ff6666', fontSize: 20 }} />
                        </div>
                      </MenubarItem>
                      {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                      <div className='px-5 grid gap-4 my-4 no-underline'>
                        <Link className='no-underline text-black-500' to='/dashboard/refer'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu((prev) => !prev)}
                          >
                            <span className='material-symbols-outlined text-xl'>emoji_events</span>
                            {/* <ShareOutlined
                            sx={{ fontSize: '20px', lineHeight: '28px', color: 'black' }}
                          /> */}
                            <p>Refer & Rewards</p>
                          </MenubarItem>
                        </Link>

                        <div>
                          <p className='text-primary-200 font-medium text-[10px] leading-3'>
                            CONNECT
                          </p>

                          <Link to='/dashboard/support'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                support_agent
                              </span>
                              <p>Support</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div className='flex justify-center'>
                          <MenubarItem className='justify-between w-36 p-0'>
                            {socials.map((social) => {
                              return (
                                <div
                                  key={social.id}
                                  className=' h-6 w-6 rounded-full flex justify-center items-center cursor-pointer'
                                >
                                  <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                    <img src={social.icon} alt={social.id} />
                                  </a>
                                </div>
                              )
                            })}
                          </MenubarItem>
                        </div>
                      </div>
                    </MenubarContent>
                  )}
                </MenubarMenu>
              </Menubar>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='px-4 py-5 flex justify-between items-center'>
            <h2 className='font-semibold text-white leading-7 tracking-[-4%] text-xl'>
              Mutual Funds
            </h2>
            <div className='flex lg:hidden justify-center items-center gap-x-4'>
              <Button variant='outlined' className='text-white p-0' onClick={handleSearchOpen}>
                <Search sx={{ padding: '0px' }} />
              </Button>

              <NotificationsNoneIcon
                id='web-inbox-placeholder'
                sx={{ width: '24px', height: '24px' }}
                className={` text-black-400  ${
                  scrollPosition < 200 ? 'text-white' : 'text-black-400'
                }`}
              />

              <div>
                <Menubar>
                  <MenubarMenu className='bg-transparent'>
                    <MenubarTrigger>
                      <Avatar
                        className='cursor-pointer'
                        onClick={() => setShowMenu((prev) => !prev)}
                      >
                        {/* <AvatarImage src={avatar} alt='User Image' /> */}

                        <AvatarFallback className='bg-primary-200'>
                          <div className='rounded-full text-white flex justify-center items-center text-xl'>
                            {userDetails?.firstName?.slice(0, 1)}
                          </div>
                        </AvatarFallback>
                      </Avatar>
                    </MenubarTrigger>
                    {/* <a className='no-underline' href='https://fabits.com/pricing' target='_blank'>
                              <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                                Pricing
                              </MenubarItem>
                            </a> */}
                    {/* <MenubarContent className='rounded-[10px] flex flex-col gap-y-1'>
                            {isDematUser() && (
                              <Link className='no-underline text-black-500' to='/dashboard/profile' className='no-underline'>
                                <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                                  Profile
                                </MenubarItem>
                              </Link>
                            )}
          
                            {isDematUser() && (
                              <Link className='no-underline text-black-500' to='/dashboard/myplan' className='no-underline'>
                                <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                                  My Plan
                                </MenubarItem>
                              </Link>
                            )}
          
                            <a
                              className='no-underline'
                              href='https://backoffice.fabits.app/shrdbms/userlogin.ss'
                              target='_blank'
                            >
                              <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                                Back Office
                              </MenubarItem>
                            </a>
          
                            <Link className='no-underline' to='/dashboard/support'>
                              <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                                Support Portal
                              </MenubarItem>
                            </Link>
          
                            <Link className='no-underline' to='/dashboard/refer'>
                              <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer bg-[#E7EDFC]'>
                                Refer & Rewards
                              </MenubarItem>
                            </Link>
          
                            <MenubarItem
                              className='py-2 pl-3 text-sm cursor-pointer bg-error-50 text-error-500'
                              onClick={handleLogout}
                            >
                              Logout
                            </MenubarItem>
                          </MenubarContent> */}

                    {isDematUser() && showMenu && (
                      <MenubarContent className='relative -top-[80px] w-screen h-screen z-[9999] bg-white flex flex-col gap-y-[2px] p-0 border-none rounded-none'>
                        <div
                          onClick={() => setShowMenu((prev) => !prev)}
                          className='flex py-4 px-5'
                        >
                          <ChevronLeft /> Profile
                        </div>

                        <div className='flex justify-center py-4 px-5'>
                          <div className='grid justify-items-center'>
                            <div className='h-[72px] w-[72px] bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                              {userDetails?.firstName?.slice(0, 1)}
                            </div>
                            <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                              {userDetails.firstName} {userDetails.lastName}
                            </div>
                          </div>
                        </div>

                        <div className='px-5 grid gap-4 my-4'>
                          <div>
                            <p className='text-primary-200 font-DMSans font-medium text-[10px] leading-3'>
                              ACCOUNT
                            </p>

                            <Link className='no-underline text-black-500' to='/dashboard/profile'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  person
                                </span>
                                <p>Account Details</p>
                              </MenubarItem>
                            </Link>

                            <Link className='no-underline text-black-500' to='/dashboard/refer'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl'>
                                  emoji_events
                                </span>
                                {/* <ShareOutlined
                                        sx={{ fontSize: '20px', lineHeight: '28px', color: 'black' }}
                                      /> */}
                                <p>Refer & Rewards</p>
                              </MenubarItem>
                            </Link>

                            {/* <Link className='no-underline text-black-500' to='/dashboard/myplan'>
                                    <MenubarItem
                                      className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                      onClick={() => setShowMenu(prev=>!prev)}
                                    >
                                      <span className='material-symbols-outlined text-xl text-black-500'>
                                        license
                                      </span>
                                      <p>Fabits Subscription</p>
                                    </MenubarItem>
                                  </Link> */}

                            <Link className='no-underline text-black-500' to='/dashboard/funds'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  account_balance_wallet
                                </span>
                                <p>Wallet</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              INVESTMENTS
                            </p>

                            <Link className='no-underline text-black-500' to='/dashboard/portfolio'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  local_mall
                                </span>
                                <p>Individual Holdings</p>
                              </MenubarItem>
                            </Link>

                            <Link className='no-underline text-black-500' to='/dashboard/sips'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  currency_exchange
                                </span>
                                <p>SIPs</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              CONNECT
                            </p>

                            <Link className='no-underline text-black-500' to='/dashboard/support'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  support_agent
                                </span>
                                <p>Support</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div className='flex justify-center'>
                            <MenubarItem className='justify-between w-44 p-0'>
                              {socials.map((social, idx) => {
                                return (
                                  <div
                                    key={social.id + idx}
                                    className=' h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                    onClick={() => window.open(social.linkTo)}
                                  >
                                    <img src={social.icon} alt={social.id} />
                                  </div>
                                )
                              })}
                            </MenubarItem>
                          </div>
                        </div>

                        <div
                          className='cursor-pointer px-5 my-4  flex justify-center'
                          onClick={() => handleLogout()}
                        >
                          <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500 hover:bg-error-200 '>
                            Logout
                          </div>
                        </div>
                      </MenubarContent>
                    )}

                    {!isDematUser() && showMenu && (
                      <MenubarContent className='relative -top-[72px] w-screen h-screen z-50 bg-white flex flex-col gap-y-[2px] p-0 border-none rounded-none'>
                        <div
                          onClick={() => setShowMenu((prev) => !prev)}
                          className='flex py-4 px-5'
                        >
                          <ChevronLeft /> Profile
                        </div>

                        <div className='flex justify-center py-4 px-5'>
                          <div className='grid justify-items-center'>
                            <div className='h-[72px] w-[72px] bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                              {userDetails?.firstName?.slice(0, 1)}
                            </div>
                            <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                              {userDetails.firstName} {userDetails.lastName}
                            </div>
                          </div>
                        </div>

                        <div className='px-5 grid gap-4 my-4'>
                          <Link className='no-underline text-black-500' to='/dashboard/refer'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu((prev) => !prev)}
                            >
                              <span className='material-symbols-outlined text-xl'>
                                emoji_events
                              </span>
                              {/* <ShareOutlined
                                      sx={{ fontSize: '20px', lineHeight: '28px', color: 'black' }}
                                    /> */}
                              <p>Refer & Rewards</p>
                            </MenubarItem>
                          </Link>
                          <div>
                            <p className='text-primary-200 font-medium text-[10px] leading-3'>
                              CONNECT
                            </p>

                            <Link className='no-underline text-black-500' to='/dashboard/support'>
                              <MenubarItem
                                className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                                onClick={() => setShowMenu((prev) => !prev)}
                              >
                                <span className='material-symbols-outlined text-xl text-black-500'>
                                  support_agent
                                </span>
                                <p>Support</p>
                              </MenubarItem>
                            </Link>
                          </div>
                          <div className='flex justify-center'>
                            <MenubarItem className='justify-between w-44 p-0'>
                              {socials.map((social, idx) => {
                                return (
                                  <div
                                    key={social.id + idx}
                                    className=' h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                    onClick={() => window.open(social.linkTo)}
                                  >
                                    <img src={social.icon} alt={social.id} />
                                  </div>
                                )
                              })}
                            </MenubarItem>
                          </div>
                        </div>

                        <div
                          className='cursor-pointer px-5 my-4  flex justify-center'
                          onClick={() => handleLogout()}
                        >
                          <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500'>
                            Logout
                          </div>
                        </div>
                      </MenubarContent>
                    )}
                  </MenubarMenu>
                </Menubar>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default MFNavbar
