import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../ui/carousel'

// import Blog1 from '../../assets/Home/Blog 1.png'
// import Blog3 from '../../assets/Home/Blog 3.png'
// import Blog4 from '../../assets/Home/Blog 4.png'
// import Video2 from '../../assets/Home/Video 2.jpeg'

// const plans = [
//   {
//     id: 1,
//     icon: Blog1,
//     title: 'When Should you Plan your Retirement? ',
//     text: 'Build your career, tension-free',
//     link: 'https://blog.fabits.com/2023/05/04/your-retirement-planning-should-have-begun-yesterday/',
//   },
//   {
//     id: 2,
//     icon: Blog3,
//     title: 'Why Invest with Fabits?',
//     text: 'Your old age, taken care of',
//     link: 'https://www.youtube.com/watch?v=Q-kB_MEBXYQ&t=35s&pp=ygUQZmFiaXRzIGludmVzdGluZw%3D%3D',
//   },
//   {
//     id: 3,
//     icon: Blog4,
//     title: 'The Best of Tech & Human Expertise in Investing',
//     text: 'Be prepared at all times',
//     link: 'https://blog.fabits.com/2023/05/04/deep-tech-and-human-expertise-get-the-best-of-both-worlds-at-fabits/',
//   },
//   {
//     id: 4,
//     icon: Video2,
//     title: 'Plan Your Next Europe Trip!',
//     text: 'Plan your dream wedding today',
//     link: 'https://blog.fabits.com/2023/04/24/vacation-planning-budget-your-trip-with-us/',
//   },
// ]

const PlansCarousel = ({ contentCarouselData }) => {
  return (
    <Carousel
      opts={{
        align: 'start',
      }}
    >
      <CarouselContent>
        {contentCarouselData.map((el, index) => (
          <CarouselItem
            key={index}
            className='basis-1/2 md:basis-1/2 lg:basis-1/2 2xl:basis-1/3 pl-1'
            onClick={() => window.open(el.link)}
          >
            <div className='relative rounded-2xl h-[142px]'>
              <img
                className='absolute inset-0 h-full w-full object-cover object-top rounded-2xl'
                src={el.data}
                alt={el.alt}
                style={{ display: 'block' }}
              />
              <div
                className='absolute inset-0 rounded-2xl'
                style={{
                  background:
                    'linear-gradient(180deg, rgba(17, 21, 18, 0.32) 43.75%, #111512 100%)',
                  boxShadow: '0px 32px 32px -32px rgba(17, 21, 18, 0.32)',
                }}
              />
              <span className='absolute bottom-2 left-4 font-semibold text-white'>{el.alt}</span>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className='absolute left-5' />
      <CarouselNext className='absolute right-5' />
    </Carousel>
  )
}

export default PlansCarousel
