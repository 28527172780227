import axios from 'axios'
import env from '../env/env'

const client = axios.create({
  baseURL: env.AUTH_API_URL,
})

const AUTH_SERVER = env.AUTHSERVER

const parseJwt = (token) => {
  const basePayload = token.split('.')[1]
  const base64 = basePayload.replace(/-/g, '+').replace(/_/g, '/')
  const payload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(payload)
}

// eslint-disable-next-line no-unused-vars
const saveToken = (access_token) => {
  localStorage.setItem('access_token', access_token)
}

const getAccessToken = async () => {
  let token = localStorage.getItem('access_token')
  if (!token) {
    return token
  }
  if (isTokenExpired(token)) {
    return await getTokenFromRefreshToken()
  }
  return token
}

const saveTokensInSession = (response) => {
  if (response.access_token) {
    localStorage.setItem('access_token', response.access_token)
    localStorage.setItem('refresh_token', response.refresh_token)
  }
}

const removeToken = async () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.clear()
  sessionStorage.clear()

  // Clear browser cache
  if ('caches' in window) {
    try {
      const cacheKeys = await caches.keys()
      for (const key of cacheKeys) {
        await caches.delete(key)
      }
      console.log('Cache cleared successfully.')
    } catch (error) {
      console.error('Error clearing cache:', error)
    }
  }

  window.location.href = '/login'
}

const getUserDetails = () => {
  const token = localStorage.getItem('access_token')
  if (token) {
    return parseJwt(token)
  }
}

const getTokenFromRefreshToken = async () => {
  const payload = {
    refresh_token: localStorage.getItem('refresh_token'),
  }
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/refreshToken`, payload)
    saveTokensInSession(response.data)
    // console.log('🚀 ~ getTokenFromRefreshToken ~ response:', response)
    return response.data.access_token
  } catch (e) {
    console.log('🚀 ~ getTokenFromRefreshToken ~ e:', e)
    if (e.response.status === 401) {
      removeToken()
    }
    return null
  }
}

const activateAccount = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/activate`, payload)
    saveTokensInSession(response.data)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const loginWithOTP = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/login/otp`, payload)
    saveTokensInSession(response.data)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const forgotPassword = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/forgotPassword`, payload)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const forgotPasswordOTP = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/validate/otp`, payload)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const changePassword = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/newPassword`, payload)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const loginWithMPIN = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/login/mpin`, payload)
    saveTokensInSession(response.data)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const loginWithDematOTP = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/verify/otp`, payload)
    saveTokensInSession(response.data)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const resendOtp = async (payload) => {
  try {
    const response = await client.post(`${AUTH_SERVER}/api/auth/resend/otp`, payload)
    return {
      code: 'Success',
      message: response.data,
    }
  } catch (e) {
    return handleError(e)
  }
}

const handleError = (e) => {
  const response = e.response
  if (response.status === 401) {
    return {
      code: 'Unauthorized',
      message: response.data,
      isError: true,
    }
  } else if (response.status === 500) {
    return {
      code: 'InternalServerError',
      message: response.data,
      isError: true,
    }
  } else {
    return {
      code: 'InternalServerError',
      message: response.data,
      isError: true,
    }
  }
}

const isTokenExpired = (accessToken) => {
  const exp = parseJwt(accessToken).exp
  return Date.now() >= exp * 1000
}

const isDematUser = () => {
  const userDetails = getUserDetails()
  if (userDetails) {
    return userDetails['custom:role'].includes('CUSTOMER') ? false : true
  } else {
    return false
  }
}

const isIPOUser = () => {
  const userDetails = getUserDetails()
  if (userDetails) {
    return userDetails['custom:role'].includes('IPO') ? true : false
  } else {
    return false
  }
}

export {
  removeToken,
  getUserDetails,
  getAccessToken,
  activateAccount,
  loginWithOTP,
  loginWithMPIN,
  isDematUser,
  loginWithDematOTP,
  resendOtp,
  forgotPassword,
  forgotPasswordOTP,
  changePassword,
  isTokenExpired,
  isIPOUser,
}
