import * as React from 'react'

import { cn } from '../../lib/utils'
import { Search } from 'lucide-react'
import { Button } from './button'

const MobileSearchInput = React.forwardRef(
  ({ className, handleSearchCancel, type, ...props }, ref) => {
    return (
      <div className='relative max-w-2xl mx-auto w-full'>
        <div className='relative flex items-center rounded-full border bg-background shadow-sm'>
          <Search className='absolute left-4 h-[14px] w-[14px] text-primary-400' />

          <input
            type={type}
            className={cn(
              'pl-12 pr-24 flex h-10 w-full rounded-full border-0 border-primary-50 bg-background text-sm font-normal ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-primary-400 placeholder:font-normal placeholder:text-sm placeholder:leading-5 focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50',
              className,
            )}
            ref={ref}
            {...props}
          />

          <Button
            variant='ghost'
            onClick={() => handleSearchCancel()}
            className='absolute right-2 px-4 text-sm text-primary-400 border-l-[1px] rounded-none hover:bg-none focus:bg-none'
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  },
)

MobileSearchInput.displayName = 'Mobile Search Input'

export { MobileSearchInput }
