/* eslint-disable */
import UPI from '../../assets/SVGS/upi.svg'
import CircleUPI from '../../assets/SVGS/circle-upi.svg'
import CircleFabits from '../../assets/SVGS/circle-fabits.svg'
import CircleRight from '../../assets/SVGS/circle-right.svg'
import { Separator } from '../../Components/ui/separator'
import { Button } from '../../Components/ui/button'
import { useEffect, useState } from 'react'
import PaymentGif from '../../assets/gifs/https___lottiefiles.com_animations_flow-tWbpYsXrBz.gif'
import bse from '../../assets/SVGS/bse.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import env from '../../env/env'
import { useTimer } from '../../hooks/useTimer'

const MFPaymentRequest = ({ bankDetails, setShowPaymentRequestPopup, paymentStatus }) => {
  const [timeLeft, setTimeLeft] = useState(300) // 5 minutes = 300 seconds
  const { time, isActive, startTimer, pauseTimer, resetTimer } = useTimer(5) // 5 minutes timer
  const navigate = useNavigate()

  useEffect(() => {
    startTimer()
  }, [])

  useEffect(() => {
    const pollingInterval = 10 // Interval in seconds

    const intervalId = setInterval(async () => {
      if (timeLeft <= 0) {
        setShowPaymentRequestPopup(false)
        clearInterval(intervalId) // Stop polling after 10 minutes
        return
      }

      console.log(`Time left: ${timeLeft} seconds`)
      paymentStatus()

      // Decrease time left using the setter
      setTimeLeft((prevTime) => prevTime - pollingInterval)
    }, pollingInterval * 1000) // Convert interval to milliseconds

    // Cleanup on unmount
    return () => clearInterval(intervalId)
  }, [timeLeft]) // Add dependencies to useEffect

  // Convert the remaining time to minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  const onClose = () => {
    setShowPaymentRequestPopup(false)
    navigate(-1)
  }

  return (
    <div className='bg-white absolute top-0 left-0 right-0 bottom-0 z-[10000] overflow-y-scroll'>
      <div className='ease-in-out h-[95vh] md:max-w-[360px] m-auto flex flex-col justify-center gap-y-12 pt-12 pb-16 px-4 lg:pb-0 lg:pt-0 lg:px-0'>
        {/* <div className='flex justify-center items-center mt-12'>
        <span className='animate-bounce text-xl border-[2px] border-secondary-600 rounded-full h-16 w-16 flex justify-center items-center'>
          {formatTime(timeLeft)}
        </span>
      </div> */}
        <div className='flex flex-col gap-y-12'>
          <div>
            <h1 className='text-center text-2xl text-black-500 font-semibold'>Payment Pending</h1>
            <p className='text-center text-sm text-black-400'>Approve payment request on UPI app</p>
          </div>
          <div className='relative flex justify-between items-center px-4 bg-primary-50 py-2 rounded-2xl h-[64px]'>
            <img src={`${env.SVGS}/logo.svg`} alt='Fabits' />
            <img
              src={PaymentGif}
              alt='Moving GIF'
              className='absolute left-[25.51%] move-left-right rotate-90 z-2'
            />
            <img src={UPI} alt='UPI Icon' />
          </div>

          <div>
            <p className='text-center text-sm text-black-400 pb-4'>
              Follow these steps to complete your payment
            </p>
            <div>
              <div className='flex gap-x-2'>
                <img src={CircleRight} alt='Right SVG' />
                <div>
                  <p className='text-sm font-semibold text-black-500'>
                    Request sent to your UPI app
                  </p>
                  <p className='text-sm text-black-500'>
                    Bank A/c ending {bankDetails?.accountNo.slice(-4)}
                  </p>
                </div>
              </div>
              <Separator className='h-6 w-[1px] mx-4 my-2' />
              <div className='flex items-center gap-x-2'>
                <img src={CircleUPI} alt='Right SVG' />
                <p className='text-sm text-black-500 font-semibold'>Open your UPI app</p>
              </div>
              <Separator className='h-6 w-[1px] mx-4 my-2' />
              <div className='flex gap-x-2'>
                <img src={bse} alt='Right SVG' />
                <div>
                  <p className='text-sm text-black-500 font-semibold'>
                    Approve the payment request
                  </p>
                  <p className='text-sm text-black-500'>
                    Request would be sent by <strong>Indian Clearing Corporation</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='text-black-400 text-center text-sm'>
          Request expires in{' '}
          <b>
            {time.minutes}:{time.seconds.toString().padStart(2, '0')}
          </b>
        </p>
        <Button className='bg-error-50 text-error-500 hover:bg-error-100' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default MFPaymentRequest
