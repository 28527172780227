import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../Components/Footer'
import BottomNavbar from '../Components/BottomNavbar'
import Header from '../Components/Header'

import Navbar from '../Components/Navbar'

const PostLoginContainer = () => {
  const location = useLocation()
  const isMFPage = location.pathname.includes('mutual-funds')

  const hideBottonNav = location.pathname.includes('tax-filing')

  const hideTopNav =
    location.pathname.includes('mutual-funds') || location.pathname.includes('tax-filing')

  return (
    <>
      {hideTopNav ? null : <Header />}

      <Navbar />

      <div className='flex justify-center min-h-screen'>
        <div
          className={`w-full ${!location.pathname.includes('/tax-filing') && 'lg:mt-[52px]'} ${
            isMFPage || location.pathname.includes('/tax-filing')
              ? 'max-w-auto mb-0'
              : 'max-w-[1128px] mb-16 lg:pb-12 lg:pt-4'
          }`}
        >
          <Outlet />
          {hideBottonNav ? null : <BottomNavbar />}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default PostLoginContainer
