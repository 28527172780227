import { useLocation, Link } from 'react-router-dom'
import { motion } from 'framer-motion'

export const Tab = ({ to, children }) => {
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link className='relative no-underline px-3 py-1 md:px-5 md:py-2' to={to}>
      <span
        className={`relative z-10 text-sm font-medium text-nowrap ${
          isActive ? 'text-primary-700' : 'text-primary-100'
        }`}
      >
        {children}
      </span>
      {isActive && (
        <motion.div
          layoutId='active-tab'
          className='absolute inset-0 bg-white rounded-[40px]'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        />
      )}
    </Link>
  )
}
