/* eslint-disable no-unused-vars */
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { post } from '../http/restservice'
import LoadingButton from '@mui/lab/LoadingButton'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import vector from '../assets/Auth/Vector.svg'
import vector1 from '../assets/Auth/Vector1.svg'
import { PasswordInput } from '../Components/ui/password-input'
import clevertap from 'clevertap-web-sdk'
import useLocalStorage from '../hooks/useLocalStorage'
import CreateSharpIcon from '@mui/icons-material/CreateSharp'

const LoginWithPassword = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState(null)
  const [navigationMessage, setNavigationMessage] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [data, setData] = useLocalStorage('userData', null)

  useEffect(() => {
    if (location) {
      setPhoneNumber(location.state?.phoneNumber)
      setNavigationMessage(location.state?.navigationMessage)
    }
  }, [])

  const validationSchema = yup.object({
    userId: yup.string('User Id').required('User Id is required'),
    password: yup.string('Password').required('Please enter your password'),
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: {
      userId: phoneNumber || '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {},
  })

  const login = async (event) => {
    event.preventDefault()
    setErrorMessage(null)
    setNavigationMessage(null)
    try {
      const payload = {
        uid: phoneNumber,
        pwd: formik.values.password,
      }
      const response = await post('/customerservice/api/customer/login', payload)

      setLoading(false)
      if (response.isError) {
        // console.log(response)
        if (response.response.message) {
          setErrorMessage(response.response.message)
        } else {
          setErrorMessage('Unable to process the request.Please try again later')
        }
        return
      }
      if (response.requestCode && response.requestCode === 117) {
        navigate('/reset-password', {
          state: {
            message: 'You are required to reset your password before proceeding',
            severity: 'success',
          },
        })
        return
      }

      setData(response)

      navigate('/otp-login', {
        state: {
          uid: response.uid,
          phoneNumber,
          firstName: response.firstName,
        },
      })
    } catch (e) {
      setLoading(false)
      return
    }
  }

  return (
    <div className='lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative !h-min'>
      <div className='flex flex-col gap-y-1 mb-6'>
        <h1 className='font-DMSans text-primary-900 text-center text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold'>
          Login
        </h1>

        <p className='font-DMSans text-center text-black-400 font-medium text-[14px] kerning-[20px] -tracking-[0.56px] flex items-center justify-center gap-x-1'>
          <svg
            width='14'
            height='14'
            viewBox='0 0 12 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.99996 14.614C1.63329 14.614 1.3194 14.4835 1.05829 14.2223C0.797182 13.9612 0.666626 13.6473 0.666626 13.2807V6.61401C0.666626 6.24735 0.797182 5.93346 1.05829 5.67235C1.3194 5.41124 1.63329 5.28068 1.99996 5.28068H2.66663V3.94735C2.66663 3.02512 2.99163 2.23901 3.64163 1.58901C4.29163 0.939014 5.07774 0.614014 5.99996 0.614014C6.92218 0.614014 7.70829 0.939014 8.35829 1.58901C9.00829 2.23901 9.33329 3.02512 9.33329 3.94735V5.28068H9.99996C10.3666 5.28068 10.6805 5.41124 10.9416 5.67235C11.2027 5.93346 11.3333 6.24735 11.3333 6.61401V13.2807C11.3333 13.6473 11.2027 13.9612 10.9416 14.2223C10.6805 14.4835 10.3666 14.614 9.99996 14.614H1.99996ZM5.99996 11.2807C6.36663 11.2807 6.68052 11.1501 6.94163 10.889C7.20274 10.6279 7.33329 10.314 7.33329 9.94735C7.33329 9.58068 7.20274 9.26679 6.94163 9.00568C6.68052 8.74457 6.36663 8.61401 5.99996 8.61401C5.63329 8.61401 5.3194 8.74457 5.05829 9.00568C4.79718 9.26679 4.66663 9.58068 4.66663 9.94735C4.66663 10.314 4.79718 10.6279 5.05829 10.889C5.3194 11.1501 5.63329 11.2807 5.99996 11.2807ZM3.99996 5.28068H7.99996V3.94735C7.99996 3.39179 7.80552 2.91957 7.41663 2.53068C7.02774 2.14179 6.55551 1.94735 5.99996 1.94735C5.4444 1.94735 4.97218 2.14179 4.58329 2.53068C4.1944 2.91957 3.99996 3.39179 3.99996 3.94735V5.28068Z'
              fill='#575757'
            />
          </svg>
          Two-factor authentication enabled
        </p>
      </div>

      <form onSubmit={login} className='flex flex-col justify-center items-center w-full h-min'>
        <div
          className='flex justify-center items-center gap-x-1 border-primary-500 border-dashed w-min border-b-[2px] mb-6 cursor-pointer'
          onClick={() => navigate('/login')}
        >
          <p className='text-primary-500 font-semibold font-DMSans whitespace-nowrap'>
            +91 {formik.values.userId}
          </p>
          <CreateSharpIcon
            className='cursor-pointer text-primary-500 text-[16px]'
            sx={{ fontSize: '16px' }}
          />
        </div>
        <div className='flex flex-col w-full'>
          <label
            htmlFor='component-outlined'
            className='required font-DMSans font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
          >
            Password
          </label>
          <PasswordInput
            id='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <p className='text-red-500 py-1'>{formik.touched.password && formik.errors.password}</p>

          {errorMessage && <p className='text-red-500 py-1 text-center'>{errorMessage}</p>}

          {navigationMessage && (
            <p className='text-secondary-500 py-1 text-center'>{navigationMessage}</p>
          )}

          {/* <TextField
            id='password'
            size='small'
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className='outline-none border-hidden'
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
            }}
          /> */}
        </div>

        <LoadingButton
          variant='contained'
          type='submit'
          loading={loading}
          sx={{
            mt: '24px',
            py: '12px',
            px: '24px',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            fontFamily: 'DM Sans',
            textTransform: 'none',
            borderRadius: { xs: '12px', md: '8px' },
            boxShadow: 'none',
            '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
            '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
          }}
          fullWidth
          disabled={!(formik.dirty && formik.isValid)}
          onClick={(event) => {
            setLoading(true)
            login(event)
          }}
        >
          Continue
        </LoadingButton>
      </form>
      <div>
        <button
          onClick={() => {
            clevertap.event.push('Clicked Button', {
              Action: 'Reset Password',
              Page_name: 'Demat Login Password',
            })
            navigate('/reset-password')
          }}
          className=' font-DMSans text-primary-500 text-sm font-semibold mt-6 border-b-2 border-dashed border-primary-500'
        >
          Reset Password
        </button>
      </div>

      <img
        className='hidden lg:block absolute top-[5%] left-[-20%] -z-[1]'
        src={vector}
        alt='Vector 1'
      />

      <img
        className='hidden lg:block absolute bottom-[10%] right-[-15%] -z-[1]'
        src={vector1}
        alt='Vector 2'
      />
    </div>
  )
}

export default LoginWithPassword
