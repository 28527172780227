/* eslint-disable */
import { ChevronLeft, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PlaceOrderBg from '../../assets/images/PlaceOrderBg.png'
import { Label } from '../../Components/ui/label'
import { Input } from '../../Components/ui/input'
import { Button } from '../../Components/ui/button'
import { toCurrency } from '../../utils/CurrencyUtil'
import { useModal } from '../../hooks/use-modal-store'
import env from '../../env/env'
import { post } from '../../http/restservice'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { MutualFundsSelectorDetails } from '../../store/MFStore'
import { Card } from '../../Components/ui/card'
import { AccountBalance } from '@mui/icons-material'
import { BankDetails } from '../../store/AccountDetailsStore'

const MFRedeem = () => {
  const location = useLocation()
  const [fundDetails] = useState(location?.state?.fundDetails)
  const [fund] = useState(location?.state?.fund)
  const fundPortfolio = useRecoilValueLoadable(
    MutualFundsSelectorDetails(location.state?.fundDetails.schemeCode),
  )

  const { contents: bankDetails } = useRecoilValueLoadable(BankDetails)
  const navigate = useNavigate()
  const { onOpen } = useModal()
  const [redemptionAmount, setRedemptionAmount] = useState(fund?.holding || 0)

  const [isLoading, setIsLoading] = useState(false)

  const setMessageModalState = useSetRecoilState(messageModalState)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const clientCode = userData?.uid.split('-')[0]

  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`
  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const redeemOrder = async () => {
    try {
      setIsLoading(true)

      const qty = redemptionAmount / fundDetails?.currentNav

      const payload = {
        orderType: 'REDEMPTION',
        schemeCode: fundDetails?.bseSchemeCode,
        clientCode: clientCode.toUpperCase(),
        qty: qty,
      }

      const response = await post('mutualfundservice/api/bseStar/mfOrder/order', payload)

      setIsLoading(false)

      if (!response.isError && response?.status === 'SUCCESS') {
        onOpen('openPopup', {
          title: 'Redemption Order Processed',
          message:
            'Your redemption order is being processed and the amount will be credited to your bank account shortly.',
          severity: 'info',
        })
      }

      if (response.isError) {
        setMessageModalState({
          message: response.response.error,
          severity: 'error',
        })
      }
    } catch (error) {
      console.log('🚀 ~ redeemOrder ~ error:', error)
    }
  }

  function getFundReturn(fundPortfolio) {
    const mainInfo = fundPortfolio?.contents?.mainInfo?.[0]

    if (mainInfo?.threeYearReturn != null) {
      return `${mainInfo.threeYearReturn.toFixed(2)}`
    } else if (mainInfo?.fiveYearReturn != null) {
      return `${mainInfo.fiveYearReturn.toFixed(2)}`
    } else if (mainInfo?.oneYearReturn != null) {
      return `${mainInfo.oneYearReturn.toFixed(2)}`
    } else {
      return 0
    }
  }

  return (
    <>
      <div className='bg-white'>
        <div className='max-w-md mx-auto min-h-screen pb-12'>
          <div
            className='flex gap-x-1 items-center font-semibold cursor-pointer lg:py-2.5 p-4 lg:px-0'
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className='h-5 w-5' />
            Redeem
          </div>

          <div className='max-w-[360px] mx-auto h-full flex flex-col gap-y-12 justify-between'>
            <div className='h-[170px] w-full bg-cover flex justify-center items-center p-2 z-0 relative'>
              <div
                className='absolute inset-0 bg-cover w-full h-full'
                style={{ backgroundImage: `url(${PlaceOrderBg})` }}
              />
              <div
                className='absolute inset-0'
                style={{
                  background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                  // boxShadow: '0px 16px 24px rgba(65, 85, 141, 0.08)',
                }}
              />
              <div className='flex items-center space-x-4 z-10'>
                <img
                  src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                  alt={fundDetails.legalName}
                  className='w-12 h-12 border rounded-lg'
                />
                <div>
                  <h3
                    className='font-semibold text-sm leading-tight text-wrap w-48'
                    style={{ textWrap: 'pretty' }}
                  >
                    {fundDetails?.legalName}
                  </h3>
                  {fundPortfolio.state === 'hasValue' && (
                    <p className='text-sm text-muted-foreground flex gap-x-2'>
                      Returns (
                      {fundPortfolio?.contents?.mainInfo[0]?.threeYearReturn != null
                        ? '3Y'
                        : fundPortfolio?.contents?.mainInfo[0]?.fiveYearReturn != null
                        ? '5Y'
                        : '1Y'}
                      ){' '}
                      <span
                        className={`font-bold text-black-500 ${
                          getFundReturn(fundPortfolio) < 0 ? 'text-error-600' : 'text-secondary-600'
                        }`}
                      >
                        {getFundReturn(fundPortfolio)} %
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className='flex justify-between'>
                <span className='text-xs text-black-400'>Current amount</span>
                <span className='font-semibold text-black-500'>₹ {toCurrency(fund?.holding)}</span>
              </div>

              {/* <div className='flex justify-between'>
                <span className='text-xs text-black-400 flex gap-x-1 items-center'>
                  Current folio value <Info fontSize='16px' color='gray' />
                </span>
                <span className='font-semibold text-black-500'>
                  ₹ {toCurrency(fundDetails.holding)}
                </span>
              </div> */}
            </div>

            <div className='flex flex-col justify-center items-center gap-1.5'>
              <Label htmlFor='amount' className='text-black-400 text-xs'>
                Redemption amount
              </Label>
              <div className='relative'>
                <Input
                  startAdornment='₹'
                  type='amount'
                  id='amount'
                  placeholder='Amount'
                  className='border-none outline-none max-w-[120px] mx-auto'
                  value={redemptionAmount.toFixed(2)}
                  onChange={(e) => setRedemptionAmount(e.target.value)}
                />
                {redemptionAmount > 0 && (
                  <Button
                    type='button'
                    variant='ghost'
                    size='sm'
                    className='absolute right-2 top-1/2 h-auto -translate-y-1/2 p-0.5 bg-black-200 text-white rounded-full hover:bg-black-200'
                    onClick={() => setRedemptionAmount(0)}
                  >
                    <X className='h-3 w-3 text-white' />
                    <span className='sr-only'>Clear input</span>
                  </Button>
                )}
              </div>
              {/* <p className='text-xs text-error-600 text-center'>
                Minimum first investment amount is ₹ {toCurrency(fundDetails?.minInvt)}
              </p> */}
            </div>

            <div className='flex justify-center gap-x-2'>
              {[25, 50, 75].map((btn, index) => (
                <Button
                  key={(btn + index).toString()}
                  size='simple'
                  className='px-3 py-[2px] rounded-[50px] bg-error-50 hover:bg-error-50 text-error-500'
                  onClick={() => setRedemptionAmount(() => (fund?.holding / 100) * btn)}
                >
                  {toCurrency(btn)} %
                </Button>
              ))}

              <Button
                size='simple'
                className='px-3 py-[2px] rounded-[50px] bg-error-50 hover:bg-error-50 text-error-500'
                onClick={() => setRedemptionAmount(fund?.holding)}
              >
                All
              </Button>
            </div>

            <div className='flex flex-col gap-y-4'>
              <Card className='flex items-center gap-3 p-4 w-full bg-white shadow-none border-[#D3DBEC] rounded-2xl'>
                <div className='flex items-center justify-center w-10 h-10 rounded-full border'>
                  {src ? (
                    <img src={src} alt='bank logos' onError={handleError} height={24} width={24} />
                  ) : (
                    <AccountBalance className='text-primary-200 w-5 h-5' />
                  )}
                  {/* <AccountBalance className='w-5 h-5' /> */}
                </div>
                <div className='flex flex-col'>
                  <h3 className='font-semibold leading-6 text-black-500'>
                    {bankDetails && bankDetails?.accountHolderName}
                  </h3>
                  <p className='text-sm text-black-400 leading-5 text-nowrap'>
                    <span className='font-semibold'>{bankDetails && bankDetails?.bankName}</span>
                    <span className='font-medium'> A/c ending in </span>
                    <span className='font-semibold'>
                      {bankDetails && bankDetails?.accountNo && bankDetails?.accountNo.slice(-4)}
                    </span>
                  </p>
                </div>
              </Card>

              <Button
                className='rounded-xl flex-1 bg-error-500 hover:bg-error-400'
                loading={isLoading}
                onClick={() => redeemOrder()}
              >
                Redeem
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MFRedeem
