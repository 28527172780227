/* eslint-disable */
import { useNavigate } from 'react-router-dom'
import starter from '../assets/MyPlan/starter.svg'
import foundation from '../assets/MyPlan/foundation.svg'
import growth from '../assets/MyPlan/growth.svg'
import premier from '../assets/MyPlan/premier.svg'
import elite from '../assets/MyPlan/elite.svg'
import greencheck from '../assets/MyPlan/greenclick.svg'
import infinite from '../assets/MyPlan/infinite.svg'
import balance from '../assets/MyPlan/balance.svg'
import sendmoney from '../assets/MyPlan/sendmoney.svg'
import supportagent from '../assets/MyPlan/supportagent.svg'
import circlecheck from '../assets/MyPlan/circlecheck.svg'
import right from '../assets/MyPlan/right.svg'
import celebration from '../assets/MyPlan/celebration.svg'

import { Button } from '../Components/ui/button'
import { useContext, useEffect, useState } from 'react'
import { ProgressTwo } from '../Components/ui/progress'
import { Separator } from '../Components/ui/separator'
import { CalendarIcon, ChevronLeft, ChevronRight, Ellipsis } from 'lucide-react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../Components/ui/table'
import { AccountDetailsNewSelector, UserDetails } from '../store/AccountDetailsStore'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-cards'

// import required modules
import { EffectCards } from 'swiper/modules'
import env from '../env/env'
import { get, getConfig, post } from '../http/restservice'
import { messageModalState } from '../store/Feedback'
import { useModal } from '../hooks/use-modal-store'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../Components/ui/form'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../Components/ui/alert-dialog'

import { Input } from '../Components/ui/input'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import useLocalStorage from '../hooks/useLocalStorage'
import { indian__currency__rounder, toCurrency } from '../utils/CurrencyUtil'
import { NavbarContext } from '../Provider/ShowNavbar'
import { EventSourcePolyfill } from 'event-source-polyfill'
import PaymentRequest from '../Components/MyPlan/PaymentRequest'
import { Calendar } from '../Components/ui/calendar'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../Components/ui/dialog'
import { Popover, PopoverContent, PopoverTrigger } from '../Components/ui/popover'
import { format } from 'date-fns'
import { cn } from '../lib/utils'
import * as Yup from 'yup'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const Starter = () => {
  return (
    <ul className='flex flex-col gap-y-2'>
      <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
        <img src={right} className='h-w w-4' alt='svg icon' />
        <p>Invest up to</p>
        <span className='text-black-500 font-semibold'>₹ 15000</span>
      </li>
      <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
        <img src={right} className='h-w w-4' alt='svg icon' />
        <p>Financial Report Card</p>
      </li>

      <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
        <img src={right} className='h-w w-4' alt='svg icon' />
        <p>Retirement Projections & Planning</p>
      </li>
    </ul>
  )
}

const Foundation = () => {
  return (
    <ul className='flex flex-col gap-y-2'>
      <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
        <img src={right} className='h-w w-4' alt='svg icon' />
        <p>Invest up to</p>
        <span className='text-black-500 font-semibold'>₹ 2 Lakhs</span>
      </li>
      <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
        <img src={right} className='h-w w-4' alt='svg icon' />
        <p>Financial Report Card</p>
      </li>

      <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
        <img src={right} className='h-w w-4' alt='svg icon' />
        <p>Retirement Projections & Planning</p>
      </li>

      <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
        <img src={right} className='h-w w-4' alt='svg icon' />
        <p>Personalised Action Items</p>
      </li>
    </ul>
  )
}

const Growth = () => {
  return (
    <>
      <p className='text-sm font-semibold text-black-500 mb-2'>Everything in Foundation, plus:</p>
      <ul className='flex flex-col gap-y-2'>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>Invest up to</p>
          <span className='text-black-500 font-semibold'>₹ 10 Lakhs</span>
        </li>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>Personalised Financial Plan</p>
        </li>

        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>
            <span>Tax</span> Planning Module
          </p>
        </li>

        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>
            <span>Automated</span>
            Rebalance
          </p>
        </li>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>
            <span>Portfolio </span>
            Management
          </p>
        </li>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>
            <span>Personal </span>
            Finance Webinars
          </p>
        </li>
      </ul>
    </>
  )
}

const Premier = () => {
  return (
    <>
      <p className='text-sm font-semibold text-black-500 mb-2'>Everything in Growth, plus:</p>
      <ul className='flex flex-col gap-y-2'>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>Invest up to</p>
          <span className='text-black-500 font-semibold'>₹ 50 Lakhs</span>
        </li>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>
            <span className='text-black-500 font-semibold'>Advanced</span> Gamification & Rewards
          </p>
        </li>
      </ul>
    </>
  )
}

const Elite = () => {
  return (
    <>
      <p className='text-sm font-semibold text-black-500 mb-2'>Everything in Premier, plus:</p>
      <ul className='flex flex-col gap-y-2'>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>Invest up to</p>
          <span className='text-black-500 font-semibold'>No Limit</span>
        </li>
        <li className='text-black-400 leading-6 flex justify-start items-center gap-x-2 text-sm'>
          <img src={right} className='h-w w-4' alt='svg icon' />
          <p>
            <span className='text-black-500 font-semibold'>Dedicated </span>
            Relationship Manager
          </p>
        </li>
      </ul>
    </>
  )
}

const plans = [
  {
    id: 1,
    title: 'Starter',
    amount: 0,
    duration: 'for life',
    icon: starter,
    features: Starter,
    maxLimit: 15000,
  },
  {
    id: 2,
    title: 'Foundation',
    amount: 49,
    duration: 'per month',
    icon: foundation,
    features: Foundation,
    maxLimit: 200000,
  },
  {
    id: 3,
    title: 'Growth',
    amount: 299,
    duration: 'per month',
    icon: growth,
    features: Growth,
    maxLimit: 1000000,
  },
  {
    id: 4,
    title: 'Premier',
    amount: 999,
    duration: 'per month',
    icon: premier,
    features: Premier,
    maxLimit: 5000000,
  },
  {
    id: 5,
    title: 'Elite',
    amount: 1999,
    duration: 'per month',
    icon: elite,
    features: Elite,
    maxLimit: 10000000,
  },
]

const features = [
  {
    title: 'Starter',
    'Monthly Subscription for AMC': 'Free',
    'Investment Limit': 'Up to ₹ 15,000',
    'Financial Report Card': true,
    'Retirement Projections & Planning': true,
    'Personalised Action Items': false,
    'Personalised Financial Plan': false,
    'Tax Planning Module': false,
    'Automated Rebalance': false,
    'Portfolio Management': false,
    'Personal Finance Webinars': false,
    'Advanced Gamification & Rewards': false,
    'Dedicated Relationship Manager': false,
  },
  {
    title: 'Foundation',
    'Monthly Subscription for AMC': '₹ 49',
    'Investment Limit': 'Up to ₹ 2 Lakhs',
    'Financial Report Card': true,
    'Retirement Projections & Planning': true,
    'Personalised Action Items': true,
    'Personalised Financial Plan': false,
    'Tax Planning Module': false,
    'Automated Rebalance': false,
    'Portfolio Management': false,
    'Personal Finance Webinars': false,
    'Advanced Gamification & Rewards': false,
    'Dedicated Relationship Manager': false,
  },
  {
    title: 'Growth',
    'Monthly Subscription for AMC': '₹ 299',
    'Investment Limit': 'Up to ₹ 10 Lakhs',
    'Financial Report Card': true,
    'Retirement Projections & Planning': true,
    'Personalised Action Items': true,
    'Personalised Financial Plan': true,
    'Tax Planning Module': true,
    'Automated Rebalance': true,
    'Portfolio Management': true,
    'Personal Finance Webinars': true,
    'Advanced Gamification & Rewards': false,
    'Dedicated Relationship Manager': false,
  },
  {
    title: 'Premier',
    'Monthly Subscription for AMC': '₹ 999',
    'Investment Limit': 'Up to ₹ 50 Lakhs',
    'Financial Report Card': true,
    'Retirement Projections & Planning': true,
    'Personalised Action Items': true,
    'Personalised Financial Plan': true,
    'Tax Planning Module': true,
    'Automated Rebalance': true,
    'Portfolio Management': true,
    'Personal Finance Webinars': true,
    'Advanced Gamification & Rewards': true,
    'Dedicated Relationship Manager': false,
  },
  {
    title: 'Elite',
    'Monthly Subscription for AMC': '₹ 1,999',
    'Investment Limit': 'No limit',
    'Financial Report Card': true,
    'Retirement Projections & Planning': true,
    'Personalised Action Items': true,
    'Personalised Financial Plan': true,
    'Tax Planning Module': true,
    'Automated Rebalance': true,
    'Portfolio Management': true,
    'Personal Finance Webinars': true,
    'Advanced Gamification & Rewards': true,
    'Dedicated Relationship Manager': true,
  },
]

const keysArray = [
  'Monthly Subscription for AMC',
  'Investment Limit',
  'Financial Report Card',
  'Retirement Projections & Planning',
  'Personalised Action Items',
  'Personalised Financial Plan',
  'Tax Planning Module',
  'Automated Rebalance',
  'Portfolio Management',
  'Personal Finance Webinars',
  'Advanced Gamification & Rewards',
  'Dedicated Relationship Manager',
]

const FormSchema = Yup.object({
  endDate: Yup.date({
    required_error: 'A date of birth is required.',
  }),
})

const MyPlan = () => {
  const { onOpen, onClose } = useModal()
  const navigate = useNavigate()
  const { contents: accountDetails } = useRecoilValueLoadable(AccountDetailsNewSelector)
  const [fetchUserPlan, setFetchUserPlan] = useState(null)
  const [error, setError] = useState(false)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [userPlan, setUserPlan] = useState(plans[0])
  const [investmentQuotaReached, setInvestmentQuotaReached] = useState(0)
  const [showPlan, setShowPlan] = useState(false)
  const [userInfo] = useLocalStorage('userData')
  const [vpaDetails, setVpaDetails] = useState(null)
  const { displayNavbar, setDisplayNavbar } = useContext(NavbarContext)
  const { state, contents: userBankDetails } = useRecoilValueLoadable(UserDetails)
  const [mandateStatus, setMandateStatus] = useState(null)
  const [messages, setMessages] = useState([])
  const [paymentRequestStatus, setPaymentRequestStatus] = useState(null)
  const [showFeatures, setShowFeatures] = useState(false)
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false)
  const [openUpdateMandate, setOpenUpdateMandate] = useState(false)
  const [selectedDate, setSelectedDate] = useState('1900-01-01')
  const [mandateTimeLeft, setMandateTimeLeft] = useState(null)
  const dateForm = useForm({
    resolver: yupResolver(FormSchema),
  })
  const currentDate = new Date()
  // Add one month
  currentDate.setMonth(currentDate.getMonth() + 1)
  // Format the date as DD/MM/YYYY
  const formattedDate = currentDate.toLocaleDateString('en-GB')

  const formSchema = yup.object().shape({
    upiId: yup.string().required('Please enter upiId.'),
  })

  const form = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: { upiId: '' },
  })

  const time = new Date().getTime()

  const isLoading = form.formState.isSubmitting
  const calculateUserPlan = (data) => {
    if (+data.amountChosen === 0) {
      setUserPlan(plans[0])
      setInvestmentQuotaReached(Math.ceil((data.currentValue / 15000) * 100))
    }

    if (+data.amountChosen === 49) {
      setUserPlan(plans[1])
      setInvestmentQuotaReached(Math.ceil((data.currentValue / 200000) * 100))
    }

    if (+data.amountChosen === 299) {
      setUserPlan(plans[2])
      setInvestmentQuotaReached(Math.ceil((data.currentValue / 1000000) * 100))
    }

    if (+data.amountChosen === 999) {
      setUserPlan(plans[3])
      setInvestmentQuotaReached(Math.ceil((data.currentValue / 5000000) * 100))
    }

    if (+data.amountChosen === 1999) {
      setUserPlan(plans[4])
      setInvestmentQuotaReached(Math.ceil((data.currentValue / 1000000000) * 100))
    }
  }

  const fetchUserCurrentPlan = async () => {
    try {
      const res = await get(`${env.API_URL}customerservice/api/subscription`)

      if (res.isError && res.errorDetails === 'Subscription charges data not exist') {
        setFetchUserPlan(null)
        setError(true)
      }

      if (res.isError) {
        setError(true)
      }

      if (!res.isError) {
        setFetchUserPlan(res.data)
        calculateUserPlan(res.data)
      }
    } catch (error) {
      console.log('[USER_PLAN_ERROR]', error)
    }
  }

  const fetchMandateStatus = async () => {
    const res = await post(`${env.API_URL}planservice/api/upiMandate/mandateStatus`, {})

    if (!res.isError) {
      const isMandateActive = res?.data?.errCodeDesc === 'Mandate Active'

      setMandateStatus(isMandateActive ? true : false)
    }
  }

  useEffect(() => {
    const currentTime = new Date().getTime()
    const mandateTime = localStorage.getItem('subscriptionMandateTimer')

    const timeLeft = 300 - Math.ceil((currentTime - mandateTime) / 1000)

    if (mandateTime) {
      if (currentTime - mandateTime < 300000) {
        setMandateTimeLeft(timeLeft)
        setPaymentRequestStatus('loading')
      }

      if (currentTime - mandateTime >= 300000) {
        localStorage.removeItem('subscriptionMandateTimer')
        setPaymentRequestStatus(null)
      }
    }
  }, [])

  useEffect(() => {
    fetchUserCurrentPlan()
    fetchMandateStatus()
  }, [])

  // useEffect(() => {
  //   let eventSource

  //   // Fetch the config and set up the SSE connection
  //   getConfig()
  //     .then((resp) => {
  //       const headers = resp.headers
  //       eventSource = new EventSourcePolyfill(
  //         `${env.API_URL}planservice/api/upiMandate/mandateStatusSSE`,
  //         {
  //           headers: {
  //             'Content-Type': 'text/event-stream',
  //             'Cache-Control': 'no-cache',
  //             Connection: 'keep-alive',
  //             // 'X-Accel-Buffering': 'no',
  //             ...headers,
  //           },
  //           heartbeatTimeout: 580000,
  //         },
  //       )

  //       eventSource.onopen = () => {
  //         console.log('SSE connection opened')
  //       }

  //       eventSource.addEventListener('MANDATE STATUS', (event) => {
  //         try {
  //           const parsedData = event.data
  //           setMandateStatus(parsedData)
  //         } catch (error) {
  //           console.log('🚀 ~ SSE Event onmessage ~ error:', error)
  //           eventSource.close()
  //         }
  //       })

  //       eventSource.onerror = (error) => {
  //         console.error('SSE Error:', error)
  //         eventSource.close()
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('Error fetching config for SSE:', err)
  //       eventSource.close()
  //     })

  //   return () => {
  //     if (eventSource) {
  //       eventSource.close()
  //     }
  //   }
  // }, [])

  function isBoolean(value) {
    return typeof value === 'boolean'
  }

  const subscribePlan = async (payload) => {
    const res = await post(`${env.API_URL}customerservice/api/subscription/upgrade`, payload)

    if (res.isError) {
      setMessageModalState({
        message:
          'We encountered an issue while processing your subscription. Please try again later. If the problem persists, contact our support team for assistance.',
        severity: 'error',
      })
    }

    if (!res.isError) {
      const title = plans.filter((p) => p.amount === payload.amount)[0].title

      setMessageModalState({
        message: `You're now enjoying the benefits of the ${title} plan on Fabits. Sit back and watch your financial dreams come true!`,
        severity: 'info',
      })

      fetchUserCurrentPlan()
    }
  }

  const createMandate = async (values, name) => {
    const res = await post(`${env.API_URL}planservice/api/upiMandate/createMandateRequest`, {
      upiId: values.upiId,
      name,
    })

    onClose()

    if (res.isError) {
      setMessageModalState({
        message: res?.data?.statusDesc
          ? res?.data?.statusDesc
          : 'Something went wrong please try after some time.',
        severity: 'error',
      })
    }

    if (!res.isError) {
      const time = new Date().getTime()
      localStorage.setItem('subscriptionMandateTimer', time)
      setPaymentRequestStatus('loading')

      let eventSource
      getConfig()
        .then((resp) => {
          const headers = resp.headers
          eventSource = new EventSourcePolyfill(
            `${env.API_URL}planservice/api/upiMandate/mandateStatusSSE`,
            {
              headers: {
                ...headers,
              },
              heartbeatTimeout: 580000,
            },
          )

          eventSource.addEventListener('MANDATE STATUS', (event) => {
            try {
              const parsedData = event.data
              if (parsedData === 'ACTIVE') {
                setMandateStatus(true)
                setPaymentRequestStatus(null)
                eventSource.close()
                localStorage.removeItem('subscriptionMandateTimer')

                setMessageModalState({
                  message: 'Mandate request completed successfully',
                  severity: 'info',
                })
                fetchMandateStatus()
              } else {
                setPaymentRequestStatus(null)
                eventSource.close()
                localStorage.removeItem('subscriptionMandateTimer')
                setMessageModalState({
                  message: 'Mandate request not completed',
                  severity: 'error',
                })
              }
            } catch (error) {
              console.log('🚀 ~ SSE Event onmessage ~ error:', error)
              eventSource.close()
            }
          })

          eventSource.onerror = (error) => {
            console.error('SSE Error:', error)
            eventSource.close()
          }
        })
        .catch((err) => {
          console.error('Error fetching config for SSE:', err)
          eventSource.close()
        })
    }
  }

  const updateMandate = async () => {
    const endDate = formatDateToDDMMYY(dateForm.getValues().endDate)

    const res = await post(`${env.API_URL}planservice/api/upiMandate/mandateUpdate`, {
      upidId: 'kajol@hdfcbank',
      endDate,
    })

    if (res.isError) {
      setOpenUpdateMandate(false)
      setMessageModalState({
        message: res?.data?.statusDesc
          ? res?.data?.statusDesc
          : 'Something went wrong please try after some time.',
        severity: 'error',
      })
    }

    if (!res.isError) {
      setOpenUpdateMandate(false)
      setMessageModalState({
        message: 'Mandate updates successfully',
        severity: 'info',
      })
    }

    dateForm.reset()
  }

  const revokeMandate = async () => {
    const res = await post(`${env.API_URL}planservice/api/upiMandate/mandateRevoke`, {
      upidId: 'kajol@hdfcbank',
    })

    if (res.isError) {
      setOpenRevokeDialog(false)
      setMessageModalState({
        message: 'Something went wrong please try after some time.',
        severity: 'error',
      })
    }

    if (!res.isError) {
      setOpenRevokeDialog(false)
      setMessageModalState({
        message: 'Mandate revoked successfully.',
        severity: 'info',
      })
    }
  }

  const onSubmit = async () => {
    try {
      const values = form.getValues()
      const response = await post(
        `${env.API_URL}planservice/api/upiMandate/checkVPA?upiId=${values.upiId}`,
        values,
      )

      if (response.code === '200' && response.status === 'SUCCESS') {
        setVpaDetails(response.data)
      }
    } catch (error) {
      console.log('🚀 ~ onSubmit ~ error:', error)
    }
  }

  const handleFormReset = () => {
    form.setValue('upiId', '')
    setVpaDetails(null)
  }

  const isLimitExceeded = (p) => {
    return fetchUserPlan &&
      fetchUserPlan.currentValue > p.maxLimit &&
      fetchUserPlan.amountChosen > p.amount
      ? true
      : false
  }

  const handleRevoke = () => {
    setOpenRevokeDialog(true)
  }

  const handleUpdate = () => {
    setOpenUpdateMandate(true)
  }

  function formatDateToDDMMYY(date) {
    let d = new Date(date)

    let day = String(d.getDate()).padStart(2, '0')
    let month = String(d.getMonth() + 1).padStart(2, '0') // Months are zero-based
    let year = String(d.getFullYear()) // Get last two digits of the year

    return `${day}${month}${year}`
  }

  return (
    <div className='max-w-[100vw]'>
      {/* Desktop Code */}
      {!showPlan && paymentRequestStatus !== 'loading' && (
        <div className='hidden lg:block max-w-[1120px]'>
          {/* Back Button */}
          <div
            className='flex flex-row justify-start items-center gap-x-1 mx-4 my-4 lg:mx-0 lg:mt-0 lg:mb-6 cursor-pointer'
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className='text-[24px]' />
            <p className='text-[16px] kerning-[24px] -tracking-[0.64px] md:text-[20px] md:kerning-[28px] md:-tracking-[0.8px] font-semibold text-black-500'>
              My Plan
            </p>
          </div>

          {/* User Plan */}

          <div className='border min-h-[256px] w-full rounded-[32px] p-8 my-6 bg-white flex justify-between'>
            <div className='flex flex-col justify-between'>
              <div>
                <h3 className='text-2xl font-semibold leading-8 tracking-[-4%] text-black-500 mb-1'>
                  Fabits {userPlan?.title}
                </h3>
                <h2 className='sm:text-[42px] font-semibold leading-[54px] tracking-[-4%] text-black-500'>
                  {userPlan?.amount === 0 ? 'Free' : `₹ ${toCurrency(userPlan?.amount)}`}
                </h2>
                <p className='text-black-200 leading-6'>{userPlan?.duration}</p>
              </div>

              <div className='flex flex-col gap-y-2'>
                <ProgressTwo
                  value={investmentQuotaReached}
                  className={`${investmentQuotaReached > 90 ? 'bg-red-500' : 'bg-secondary-100'}`}
                />
                <p className='text-black-400 font-medium'>
                  <span className='text-black-500 font-semibold'>
                    {investmentQuotaReached > 100 ? 100 : investmentQuotaReached}%
                  </span>{' '}
                  Investment Quota Reached
                </p>
              </div>
            </div>
            <div className='mx-12'>
              <h3 className='text-2xl font-semibold mb-2'>Current Benefits</h3>
              <ul className='flex flex-col gap-y-2'>
                <li className='flex gap-x-2'>
                  <img src={greencheck} /> Invest up to ₹{' '}
                  {indian__currency__rounder(userPlan.maxLimit)}
                </li>
                {userPlan.amount !== 0 && (
                  <li className='flex gap-x-2'>
                    <img src={greencheck} /> Holistic Financial Plan
                  </li>
                )}
                {userPlan.amount !== 0 && (
                  <li className='flex gap-x-2'>
                    <img src={greencheck} /> Financial Report Card
                  </li>
                )}
              </ul>
            </div>
            <Separator orientation='vertical' className='h-auto' />
            {((error && !fetchUserPlan) || userPlan.amount === 0) && (
              <div className='ml-12'>
                <h3 className='text-2xl font-semibold mb-2'>Upgrade for better investing</h3>
                <ul className='flex flex-col gap-y-2'>
                  <li className='text-black-400 leading-6 flex items-center gap-x-2'>
                    <img src={infinite} alt='svg icon' />
                    <span className='text-black-500 font-semibold'>No limit</span> on investments
                  </li>
                  <li className='text-black-400 leading-6 flex items-center gap-x-2'>
                    <img src={balance} alt='svg icon' />
                    <span className='text-black-500 font-semibold'>Automated</span> Rebalance &
                    Portfolio Management
                  </li>
                  <li className='text-black-400 leading-6 flex items-center gap-x-2'>
                    <img src={sendmoney} alt='svg icon' />
                    <span className='text-black-500 font-semibold'>Tax Planning Module</span>
                  </li>
                  <li className='text-black-400 leading-6 flex items-center gap-x-2'>
                    <img src={supportagent} alt='svg icon' />
                    <span className='text-black-500 font-semibold'>Dedicated</span> Relationship
                    Manager
                  </li>
                  <li className='text-black-400 leading-6 flex items-center gap-x-2'>
                    <Ellipsis className='text-primary-500' />
                    <span className='text-primary-500 font-semibold border-b-2 border-primary-500 border-dashed'>
                      many more benefits!
                    </span>
                  </li>
                </ul>
              </div>
            )}

            {/* <div className='ml-12 flex flex-col justify-center items-center'>
              <img src={celebration} alt='Celebration SVG' className='h-21 w-21' />
              <h3 className='text-2xl font-semibold mb-2 text-center'>Great job!</h3>

              <p className='text-black-400 text-center'>
                You've unlocked the ultimate level of financial <br /> habit building with Fabits!
              </p>
            </div> */}

            {!error &&
              userPlan.amount !== 0 &&
              fetchUserPlan &&
              (fetchUserPlan && !fetchUserPlan?.isAmountPaid && !mandateStatus ? (
                <div
                  className='border rounded-[20px] p-5 w-1/3 ml-12 flex flex-col justify-between gap-y-4'
                  style={{
                    background:
                      'radial-gradient(77.56% 111.28% at 94.73% 7.32%, #DD9365 0%, #843C21 100%)',
                    boxShadow: '0px 8px 24px rgba(65, 85, 141, 0.12)',
                  }}
                >
                  <h2 className='text-xl font-semibold text-white'>Membership Inactive</h2>
                  <p className='text-sm text-white'>
                    Set up your UPI mandate today to enjoy <br /> uninterrupted investing with
                    Fabits!
                  </p>
                  <Button
                    className='bg-white hover:bg-white border flex justify-between items-center text-[#853C22] text-sm font-medium py-[10px] pl-4 pr-3 truncate'
                    onClick={() =>
                      onOpen('openSubscription', {
                        userPlan,
                        onSubmit,
                        createMandate,
                        mandateStatus,
                      })
                    }
                  >
                    Finish setting up UPI mandate
                    <ChevronRight className='h-5' />
                  </Button>
                </div>
              ) : (
                <div
                  className='border rounded-[20px] p-5 w-1/3 ml-12 flex flex-col justify-between gap-y-4'
                  style={{
                    background:
                      'radial-gradient(77.56% 111.28% at 94.73% 7.32%, #7EDD65 0%, #368421 100%)',
                    boxShadow: '0px 8px 24px rgba(65, 85, 141, 0.12)',
                  }}
                >
                  <div>
                    <h2 className='text-xl font-semibold text-white'>Membership Active</h2>
                    {/* <p className='text-sm text-white'>UPI Mandate successfully set up</p>
                     */}
                    <div className='flex gap-x-1 flex-wrap'>
                      <h4 className='text-sm font-medium text-white'>Member since</h4>
                      <p className='text-sm font-semibold text-white'>
                        {new Date(fetchUserPlan.createdTimestamp).toLocaleDateString('en-GB')}
                      </p>
                    </div>
                  </div>

                  <Button
                    className='bg-white hover:bg-white border flex justify-between items-center text-[#3C8B27] text-sm font-medium py-[10px] pl-4 pr-3 truncate'
                    onClick={() =>
                      onOpen('openMandateDetails', {
                        userPlan,
                        displayNavbar,
                        userBankDetails,
                        fetchUserPlan,
                        handleRevoke,
                        handleUpdate,
                      })
                    }
                  >
                    Mandate details & payments
                    <ChevronRight className='h-5' />
                  </Button>
                </div>
              ))}
          </div>

          {/* Fabits Plans */}

          <div>
            <div className='mb-6 mt-12'>
              <h1 className='text-2xl font-semibold text-black-500 leading-8 tracking-[-4%]'>
                Upgrade your Fabits plan
              </h1>
            </div>
            <div className='grid grid-cols-[repeat(auto-fill,minmax(206px,1fr))] gap-2'>
              {plans.map((plan) => (
                <div
                  key={plan.id + plan.title}
                  className='p-4 border rounded-[20px] w-[206px] bg-white'
                >
                  <div className='mb-12'>
                    <img src={plan.icon} alt={plan.title} className='mb-4' />
                    <h3 className='text-2xl font-semibold leading-8 tracking-[-4%] text-black-500'>
                      {plan.title}
                    </h3>
                    <h2 className='sm:text-[42px] font-semibold leading-[54px] tracking-[-4%] text-black-500'>
                      {plan.amount === 0 ? 'Free' : `₹ ${toCurrency(plan.amount)}`}
                    </h2>
                    <p className='text-black-200 leading-6'>{plan.duration}</p>
                  </div>
                  {isLimitExceeded(plan) && (
                    <Button
                      className={`w-full border text-primary-300 bg-white font-medium text-sm`}
                      disabled
                    >
                      Limit exceeded
                    </Button>
                  )}
                  {plan.amount > userPlan?.amount && (
                    <Button
                      className={`w-full`}
                      onClick={() => subscribePlan({ amount: plan.amount })}
                    >
                      Upgrade
                      {/* {plan.title} */}
                    </Button>
                  )}

                  {plan.amount < userPlan?.amount && !isLimitExceeded(plan) && (
                    <Button
                      className='w-full bg-error-600 hover:bg-red-400'
                      onClick={() => subscribePlan({ amount: plan.amount })}
                    >
                      {/* {plan.title}
                       */}
                      Downgrade
                    </Button>
                  )}

                  {plan.amount == userPlan?.amount && (
                    <Button
                      className={`w-full border text-primary-300 bg-white font-medium text-sm`}
                      disabled
                    >
                      Current Plan
                    </Button>
                  )}

                  {/* {error && !fetchUserPlan && (
                    <Button
                      onClick={() => plan.amount !== '0' && subscribePlan({ amount: plan.amount })}
                      className={`w-full border text-primary-300 bg-white font-medium text-sm`}
                      disabled={plan.amount === '0'}
                    >
                      {plan.amount === '0' ? 'Current Plan' : `Upgrade to ${plan.title}`}
                    </Button>
                  )} */}
                </div>
              ))}
            </div>
          </div>

          {/* Plan Features */}

          <div className='border mt-6 p-8 bg-white rounded-[32px]'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='text-black-500 leading-6 font-semibold'>Features</TableHead>
                  <TableHead className='text-black-500 leading-6 font-semibold'>Starter</TableHead>
                  <TableHead className='text-black-500 leading-6 font-semibold'>
                    Foundation
                  </TableHead>
                  <TableHead className='text-black-500 leading-6 font-semibold'>Growth</TableHead>
                  <TableHead className='text-black-500 leading-6 font-semibold'>Premier</TableHead>
                  <TableHead className='text-black-500 leading-6 font-semibold'>Elite</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {keysArray.map((key, i) => (
                  <TableRow key={i}>
                    <TableCell className='text-black-400 font-medium'>{key}</TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[0][key]) ? (
                        features[0][key]
                      ) : isBoolean(features[0][key]) && features[0][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[1][key]) ? (
                        features[1][key]
                      ) : isBoolean(features[1][key]) && features[1][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[2][key]) ? (
                        features[2][key]
                      ) : isBoolean(features[2][key]) && features[2][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[3][key]) ? (
                        features[3][key]
                      ) : isBoolean(features[3][key]) && features[3][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[4][key]) ? (
                        features[4][key]
                      ) : isBoolean(features[4][key]) && features[4][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}

      {/* Mobile & Tab Code */}
      {!showPlan && paymentRequestStatus !== 'loading' && (
        <div className='lg:hidden'>
          {/* Back Button */}
          <div className='flex flex-row justify-start items-center gap-x-1 mx-4 my-4 lg:mx-0 lg:mt-0 lg:mb-6'>
            <ChevronLeftIcon className='text-[24px]' />
            <p className='text-[16px] kerning-[24px] -tracking-[0.64px] md:text-[20px] md:kerning-[28px] md:-tracking-[0.8px] font-semibold text-black-500'>
              My Plan
            </p>
          </div>

          {/* User Plan */}

          <div className='border-y min-h-[256px] w-full p-5 my-6 bg-white flex flex-col gap-y-6'>
            <div className='flex flex-col gap-y-6 justify-between'>
              <img className='h-11 w-11' src={userPlan?.icon} alt={userPlan?.title} />

              <div>
                <h3 className='text-xl font-semibold leading-8 tracking-[-4%] text-black-500 mb-1'>
                  Fabits {userPlan?.title}
                </h3>
                <h2 className='text-4xl font-semibold leading-[54px] tracking-[-4%] text-black-500'>
                  {userPlan?.amount === 0 ? 'Free' : `₹ ${userPlan?.amount}`}
                </h2>
                <p className='text-black-200 leading-6 text-sm'>{userPlan?.duration}</p>
              </div>

              <div className='flex flex-col gap-y-2'>
                <ProgressTwo value={investmentQuotaReached} />
                <p className='text-black-400 font-medium text-sm'>
                  <span className='text-black-500 font-semibold'>{investmentQuotaReached}%</span>{' '}
                  Investment Quota Reached
                </p>
              </div>
            </div>

            {/* <div>
            <h3 className='text-2xl font-semibold mb-2'>Current Benefits</h3>
            <ul className='flex flex-col gap-y-2'>
              <li className='flex gap-x-2'>
                <img src={greencheck} /> Invest up to ₹ 2 Lakhs
              </li>
              <li className='flex gap-x-2'>
                <img src={greencheck} /> Holistic Financial Plan
              </li>
              <li className='flex gap-x-2'>
                <img src={greencheck} /> Financial Report Card
              </li>
            </ul>
          </div> */}

            {((error && !fetchUserPlan) || userPlan.amount === 0) && (
              <>
                <div>
                  <h3 className='text-xl font-semibold mb-2'>Upgrade now for</h3>
                  <ul className='flex flex-col gap-y-2'>
                    <li className='text-black-400 leading-6 flex items-center gap-x-2 text-sm'>
                      <img src={infinite} className='h-w w-4' alt='svg icon' />
                      <span className='text-black-500 font-semibold'>No limit</span> on investments
                    </li>
                    <li className='text-black-400 leading-6 flex items-center gap-x-2 text-sm'>
                      <img src={balance} className='h-w w-4' alt='svg icon' />
                      <span className='text-black-500 font-semibold'>Automated</span> Rebalance &
                      Portfolio Management
                    </li>
                    <li className='text-black-400 leading-6 flex items-center gap-x-2 text-sm'>
                      <img src={sendmoney} className='h-w w-4' alt='svg icon' />
                      <span className='text-black-500 font-semibold'>Tax Planning Module</span>
                    </li>
                    <li className='text-black-400 leading-6 flex items-center gap-x-2 text-sm'>
                      <img src={supportagent} className='h-w w-4' alt='svg icon' />
                      <span className='text-black-500 font-semibold'>Dedicated</span> Relationship
                      Manager
                    </li>
                    <li className='text-black-400 leading-6 flex items-center gap-x-2 text-sm'>
                      <Ellipsis className='text-primary-500 h-4 w-4' />
                      <span className='text-primary-500 font-semibold border-b-2 border-primary-500 border-dashed'>
                        many more benefits!
                      </span>
                    </li>
                  </ul>
                </div>

                <Button className='rounded-xl text-sm'>
                  <a href='#plans' className='w-full'>
                    Get more benefits
                  </a>
                </Button>
              </>
            )}

            {!error &&
              userPlan.amount !== 0 &&
              (fetchUserPlan && !fetchUserPlan?.isAmountPaid && !mandateStatus ? (
                <div
                  className='border rounded-[20px] p-5 w-full flex flex-col justify-between gap-y-4'
                  style={{
                    background:
                      'radial-gradient(77.56% 111.28% at 94.73% 7.32%, #DD9365 0%, #843C21 100%)',
                    boxShadow: '0px 8px 24px rgba(65, 85, 141, 0.12)',
                  }}
                >
                  <h2 className='text-xl font-semibold text-white'>Membership Inactive</h2>
                  <p className='text-sm text-white'>
                    Set up your UPI mandate today to enjoy <br /> uninterrupted investing with
                    Fabits!
                  </p>
                  <Button
                    className='bg-white hover:bg-white border flex justify-between items-center text-[#853C22] text-sm font-medium py-[10px] pl-4 pr-3'
                    onClick={() => setShowPlan(true)}
                  >
                    Finish setting up UPI mandate
                    <ChevronRight className='h-5' />
                  </Button>
                </div>
              ) : (
                <div
                  className='border rounded-[20px] p-5 w-full flex flex-col justify-between gap-y-4'
                  style={{
                    background:
                      'radial-gradient(77.56% 111.28% at 94.73% 7.32%, #7EDD65 0%, #368421 100%)',
                    boxShadow: '0px 8px 24px rgba(65, 85, 141, 0.12)',
                  }}
                >
                  <div className='mb-4'>
                    <h2 className='text-xl font-semibold text-white'>Membership Active</h2>
                    {/* <p className='text-sm text-white'>UPI Mandate successfully set up</p> */}
                    <div className='flex gap-x-1'>
                      <h4 className='text-sm font-medium text-white'>Member since</h4>
                      <p className='text-sm font-semibold text-white'>
                        {new Date(fetchUserPlan.createdTimestamp).toLocaleDateString('en-GB')}
                      </p>
                    </div>
                  </div>

                  <Button
                    className='bg-white hover:bg-white border flex justify-between items-center text-[#3C8B27] text-sm font-medium py-[10px] pl-4 pr-3 overflow-clip'
                    onClick={() =>
                      onOpen('openMandateDetails', {
                        userPlan,
                        displayNavbar,
                        userBankDetails,
                        fetchUserPlan,
                        handleRevoke,
                        handleUpdate,
                      })
                    }
                  >
                    Mandate details & payments
                    <ChevronRight className='h-5' />
                  </Button>
                </div>
              ))}

            {/* <div className='flex flex-col justify-center items-center'>
                <img src={celebration} alt='Celebration SVG' className='h-21 w-21' />
                <h3 className='text-2xl font-semibold mb-2 text-center'>Great job!</h3>
                <p className='text-black-400 text-center'>
                  You've unlocked the ultimate level of financial habit building with Fabits!
                </p>
              </div> */}
          </div>

          {/* Fabits Plans */}

          <div>
            <div id='plans' className='my-6'>
              <h1 className='text-2xl font-semibold text-black-500 leading-8 tracking-[-4%] text-center'>
                Upgrade your Fabits plan
              </h1>
            </div>
            {/* <div className='flex w-full overflow-x-scroll gap-x-4'>
            {plans.map((plan) => (
              <div key={plan.id + plan.title} className='p-4 border rounded-[20px] bg-white'>
                <div className='mb-12'>
                  <img src={plan.icon} alt={plan.title} className='mb-4' />
                  <h3 className='text-2xl font-semibold leading-8 tracking-[-4%] text-black-500'>
                    {plan.title}
                  </h3>
                  <h2 className='sm:text-[42px] font-semibold leading-[54px] tracking-[-4%] text-black-500'>
                    {plan.amount === 0 ? 'Free' : `₹ ${plan.amount}`}
                  </h2>
                  <p className='text-black-200 leading-6'>{plan.duration}</p>
                </div>
                <div>{<plan.features />}</div>
                <Button className={`w-full`}>Upgrade to {plan.title}</Button>
              </div>
            ))}
          </div> */}

            <div className='overflow-hidden'>
              <Swiper
                // initialSlide={userPlan?.id}
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className='w-[70%] ml-11'
              >
                {plans.map((plan) => (
                  <SwiperSlide
                    key={plan.id + plan.title}
                    className='p-4 border rounded-[20px] bg-white'
                  >
                    <div className='flex flex-col justify-between min-h-[500px]'>
                      <div>
                        <div>
                          <img src={plan.icon} alt={plan.title} className='mb-4' />
                          <h3 className='text-2xl font-semibold leading-8 tracking-[-4%] text-black-500'>
                            {plan.title}
                          </h3>
                          <h2 className='sm:text-[42px] font-semibold leading-[54px] tracking-[-4%] text-black-500'>
                            {plan.amount === 0 ? 'Free' : `₹ ${toCurrency(plan.amount)}`}
                          </h2>
                          <p className='text-black-200 leading-6'>{plan.duration}</p>
                        </div>
                        <Separator className='my-4' />
                        <div>{<plan.features />}</div>
                      </div>
                      {isLimitExceeded(plan) && (
                        <Button
                          className={`border text-primary-300 bg-white font-medium text-sm p-0`}
                          disabled
                        >
                          Limit exceeded
                        </Button>
                      )}
                      {plan.amount > userPlan.amount && (
                        <Button
                          onClick={() => subscribePlan({ amount: plan.amount })}
                          className='p-0'
                        >
                          Upgrade to {plan.title}
                        </Button>
                      )}

                      {plan.amount < userPlan.amount && !isLimitExceeded(plan) && (
                        <Button
                          className='bg-error-600 hover:bg-red-400 p-0'
                          onClick={() => subscribePlan({ amount: plan.amount })}
                        >
                          Downgrade to {plan.title}
                        </Button>
                      )}

                      {plan.amount == userPlan.amount && (
                        <Button
                          className={`border text-primary-300 bg-white font-medium text-sm p-0`}
                          disabled
                        >
                          Current Plan
                        </Button>
                      )}

                      {/* {error && !fetchUserPlan && (
                        <Button
                          onClick={() =>
                            plan.amount !== '0' && subscribePlan({ amount: plan.amount })
                          }
                          className={`border text-primary-300 bg-white font-medium text-sm p-0`}
                          disabled={plan.amount === '0'}
                        >
                          {plan.amount === '0' ? 'Current Plan' : `Upgrade to ${plan.title}`}
                        </Button>
                      )} */}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          {/* Plan Features */}

          {/* <div className='border-y mt-6 p-5 bg-white'>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className='text-black-500 leading-6 font-semibold'>Features</TableHead>
                <TableHead className='text-black-500 leading-6 font-semibold'>Starter</TableHead>
                <TableHead className='text-black-500 leading-6 font-semibold'>Foundation</TableHead>
                <TableHead className='text-black-500 leading-6 font-semibold'>Growth</TableHead>
                <TableHead className='text-black-500 leading-6 font-semibold'>Premier</TableHead>
                <TableHead className='text-black-500 leading-6 font-semibold'>Elite</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {keysArray.map((key, i) => (
                <>
                  <TableRow key={i}>
                    <TableCell className='text-black-400 font-medium'>{key}</TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[0][key]) ? (
                        features[0][key]
                      ) : isBoolean(features[0][key]) && features[0][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[1][key]) ? (
                        features[1][key]
                      ) : isBoolean(features[1][key]) && features[1][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[2][key]) ? (
                        features[2][key]
                      ) : isBoolean(features[2][key]) && features[2][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[3][key]) ? (
                        features[3][key]
                      ) : isBoolean(features[3][key]) && features[3][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell
                      className={`${
                        key === 'Monthly Subscription for AMC' &&
                        'text-black-500 font-semibold leading-6'
                      } ${key === 'Investment Limit' && 'text-black-500 font-medium leading-6'}`}
                    >
                      {!isBoolean(features[4][key]) ? (
                        features[4][key]
                      ) : isBoolean(features[4][key]) && features[4][key] ? (
                        <img src={circlecheck} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </div> */}
        </div>
      )}

      {showPlan && paymentRequestStatus !== 'loading' && (
        <div className='pb-12'>
          <p className='py-3 border-b px-2 flex items-center' onClick={() => setShowPlan(false)}>
            <ChevronLeft className='h-5' />
            Review Your Plan
          </p>
          <div className='flex flex-col gap-y-2 justify-between bg-white'>
            <div className='p-5'>
              <div>
                <img scr='' alt='' />
                <h3>Fabits {userPlan?.title}</h3>
                <h3 className='font-semibold text-[42px]'>
                  ₹ {userPlan?.amount}{' '}
                  <span className='text-black-200 font-medium text-base'>/mo.</span>
                </h3>

                <Button
                  variant='link'
                  className='p-0 m-0'
                  onClick={() => setShowFeatures(!showFeatures)}
                >
                  What will you get?
                </Button>
              </div>

              {showFeatures && <div>{userPlan && <userPlan.features />}</div>}
            </div>

            <div className='border-b my-3' />
            <div className='min-w-[372px] p-5'>
              <div className='flex justify-between font-semibold text-xl'>
                <h3>Mandate Amount</h3>
                <h3>
                  ₹ 1,999
                  <br />
                  <span className='text-black-200 font-medium text-sm'>+18% GST</span>
                </h3>
              </div>
              <div className='bg-secondary-50 rounded-[12px] py-[10px] pl-3 pr-2 flex items-center gap-x-3'>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14V10C11 9.71667 10.9042 9.47917 10.7125 9.2875C10.5208 9.09583 10.2833 9 10 9C9.71667 9 9.47917 9.09583 9.2875 9.2875C9.09583 9.47917 9 9.71667 9 10V14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z'
                    fill='#74A766'
                  />
                </svg>

                <div>
                  <p className='text-secondary-600 font-semibold'>Mandate amount covers upgrades</p>
                  <p className='text-secondary-500 font-medium text-sm'>
                    You’ll only be charged ₹ {userPlan?.amount}
                  </p>
                </div>
              </div>
              <Separator className='my-6' />
              <div className='flex flex-col gap-y-2'>
                <p>Choose your preferred method</p>
                <div className='border rounded-[16px] bg-[#F4F7FE] min-h-40 px-3 py-5 flex flex-col gap-y-2'>
                  <Form {...form}>
                    <form className='space-y-8'>
                      <FormField
                        control={form.control}
                        name='upiId'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Enter UPI Address</FormLabel>
                            <FormControl>
                              <Input
                                disabled={isLoading || vpaDetails}
                                placeholder='eg. dhruv@okhdfcbank'
                                endAdornment={`${vpaDetails ? 'Reset' : 'Verify'}`}
                                handleSubmit={
                                  vpaDetails
                                    ? form.handleSubmit(handleFormReset)
                                    : form.handleSubmit(onSubmit)
                                }
                                {...field}
                              />
                            </FormControl>
                            {/* <FormDescription>This is your public display name.</FormDescription> */}
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </form>
                  </Form>

                  {vpaDetails && vpaDetails.is_vpa_valid === 'N' && (
                    <p className='px-1 text-sm text-error-500'>UPI ID is not valid</p>
                  )}

                  {vpaDetails && vpaDetails.is_vpa_valid === 'Y' && (
                    <div className='py-2 px-1'>
                      <div className='flex gap-x-2 items-center'>
                        <img src={circlecheck} alt='upi check' className='h-4 w-4' />
                        <p className='text-secondary-500 font-semibold text-sm'>
                          {vpaDetails?.payeeType?.name}
                        </p>
                      </div>
                      <p className='text-black-400 text-sm flex items-center px-6'>
                        XXXX
                        {vpaDetails?.payeeType?.merchantInfo?.code}
                      </p>
                    </div>
                  )}
                </div>

                <Button
                  type='submit'
                  className='w-full my-2'
                  onClick={() => createMandate(form.getValues(), vpaDetails?.payeeType.name)}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {paymentRequestStatus === 'loading' && (
        <div className='payment-overlay'>
          <PaymentRequest
            closeConnection={() => {
              setPaymentRequestStatus(null)
            }}
            mandateStatus={mandateStatus}
            vpaDetails={vpaDetails}
            mandateTimeLeft={mandateTimeLeft}
          />
        </div>
      )}

      {openRevokeDialog && (
        <AlertDialog open={openRevokeDialog}>
          <AlertDialogContent className='font-DMSans w-full max-h-[250px]'>
            <AlertDialogHeader>
              <AlertDialogTitle className='text-start font-semibold text-2xl'>
                Are you sure you want to proceed?
              </AlertDialogTitle>
              <AlertDialogDescription className='text-start text-sm text-black-400'>
                Revoking your UPI mandate will end your Fabits subscription, and you will no longer
                be able to invest in funds.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <div className='flex gap-x-2 lg:justify-end'>
              <Button
                className='text-primary-500 place-content-end bg-white border hover:bg-white'
                onClick={() => setOpenRevokeDialog(false)}
              >
                No, go back
              </Button>
              <Button
                className='text-white bg-error-500 hover:bg-error-400'
                onClick={() => revokeMandate()}
              >
                Yes, revoke
              </Button>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      )}

      {openUpdateMandate && (
        <Dialog open={openUpdateMandate}>
          <DialogContent className='font-DMSans max-h-full'>
            <DialogHeader>
              <DialogTitle className='text-start font-semibold text-2xl'>
                Update your mandate!
              </DialogTitle>
              <DialogDescription className='text-start text-sm text-black-400'>
                Updating your UPI mandate will continue Fabits subscription, and you will be able to
                invest in funds.
              </DialogDescription>
            </DialogHeader>

            <Form {...dateForm}>
              <form className='space-y-8'>
                <FormField
                  control={dateForm.control}
                  name='endDate'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>Choose Mandate End Date</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-[240px] pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground',
                              )}
                            >
                              {field.value ? format(field.value, 'PPP') : <span>Pick a date</span>}
                              <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className='w-auto p-0' align='start'>
                          <Calendar
                            mode='single'
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) => date < new Date() || date < new Date('1900-01-01')}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>

            <div className='flex gap-x-2 lg:justify-end'>
              <Button
                className='text-primary-500 place-content-end bg-white border hover:bg-white'
                onClick={() => setOpenUpdateMandate(false)}
              >
                No, go back
              </Button>
              <Button
                className='text-white bg-secondary-500 hover:bg-secondary-400'
                onClick={() => updateMandate()}
              >
                Yes, Update
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default MyPlan
