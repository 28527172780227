/* eslint-disable */
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import rocket from '../../assets/SVGS/rocket.svg'
import { Button } from '../ui/button'
import { motion } from 'framer-motion'
import { useState } from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import { ChevronRight } from 'lucide-react'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'
import { useNavigate } from 'react-router-dom'
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material'
import Loader from '../Loader/Loader'

const BestPerformingTable = ({
  funds,
  sortByYearBestFunds,
  sortOrder,
  setSortOrder,
  isLoading,
  clearAllFilter,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const navigate = useNavigate()

  return (
    <div className='w-full'>
      <Table>
        {isDesktop && (
          <TableHeader className='bg-[#F4F7FE]'>
            <TableRow className='border-none'>
              <TableHead className='text-primary-400 py-3 h-0 rounded-l-xl pl-[75px]'>
                Fund Name
              </TableHead>
              <TableHead className='text-primary-400 py-3 flex justify-center items-center'>
                <Button
                  size='simple'
                  variant='simple'
                  className='p-0 m-0 flex gap-x-1'
                  onClick={() => setSortOrder((prev) => (prev === 'desc' ? 'asc' : 'desc'))}
                >
                  {sortOrder === 'desc' ? <ArrowCircleDown /> : <ArrowCircleUp />}
                  {sortByYearBestFunds} returns
                </Button>
              </TableHead>
              <TableHead className='text-primary-400 py-3 h-0 text-right rounded-r-xl'>
                Min. amount
              </TableHead>
            </TableRow>
          </TableHeader>
        )}

        {funds?.length > 0 ? (
          <TableBody>
            {funds?.map((fund, index) => (
              <TableRow
                // ref={lastPhotoRef}
                onClick={() =>
                  navigate(`/dashboard/mutual-funds/details`, {
                    state: {
                      fundDetails: fund,
                      schemeCode: fund.schemeCode,
                    },
                  })
                }
                className='cursor-pointer'
                key={index}
                onMouseEnter={() => {
                  setIsHovered(true), setCurrentIndex(index)
                }}
                onMouseLeave={() => {
                  setIsHovered(false), setCurrentIndex(null)
                }}
                style={{
                  boxShadow:
                    isHovered &&
                    currentIndex === index &&
                    '0px 24px 24px -20px rgba(0, 0, 0, 0.08)',
                }}
              >
                <TableCell className='font-medium'>
                  <div className='flex items-center space-x-3'>
                    <img
                      src={`${env.MF_LOGOS_URL}/${fund.amcCode}.png`}
                      alt={`${fund.name} icon`}
                      className='rounded-[8px] h-9 w-9 md:h-12 md:w-12'
                    />
                    <div>
                      <p className='max-w-[180px] md:max-w-full font-semibold text-sm lg:text-base text-nowrap truncate'>
                        {fund.legalName
                          ? fund.legalName.replace(/Fund.*$/, 'Fund')
                          : fund.sName.replace(/Fund.*$/, 'Fund')}
                      </p>
                      <span className='font-medium text-xs text-black-400 lg:hidden'>
                        Starts at <b>₹ {toCurrency(fund.minInvt)}</b>
                      </span>
                    </div>
                  </div>
                </TableCell>
                {isDesktop ? (
                  <TableCell className='text-center font-semibold text-secondary-600 text-nowrap'>
                    {sortByYearBestFunds === '5Y'
                      ? fund.fiveYrRet.toFixed(2)
                      : sortByYearBestFunds === '3Y'
                      ? fund.threeYrRet.toFixed(2)
                      : fund.oneYrRet.toFixed(2)}{' '}
                    %
                  </TableCell>
                ) : (
                  <TableCell className='flex justify-end items-start font-semibold text-secondary-600 text-nowrap'>
                    {sortByYearBestFunds === '5Y'
                      ? fund.fiveYrRet.toFixed(2)
                      : sortByYearBestFunds === '3Y'
                      ? fund.threeYrRet.toFixed(2)
                      : fund.oneYrRet.toFixed(2)}{' '}
                    %
                  </TableCell>
                )}

                <TableCell className='hidden lg:flex gap-x-6 justify-end items-center'>
                  <button
                    className='relative overflow-hidden font-medium py-2 px-8 transition-all duration-300 ease-in-out'
                    onMouseEnter={() => {
                      setIsHovered(true), setCurrentIndex(index)
                    }}
                    onMouseLeave={() => {
                      setIsHovered(false), setCurrentIndex(null)
                    }}
                    style={{ width: '200px' }} // Fixed width to accommodate both texts
                  >
                    <motion.span
                      className='inline-block'
                      animate={{ x: isHovered && currentIndex === index ? -40 : 60 }}
                      transition={{ type: 'tween', duration: 0.2 }}
                    >
                      ₹ {toCurrency(fund.minInvt)}
                    </motion.span>
                    <motion.div
                      className='absolute inset-y-0 right-0 flex items-center justify-center bg-primary-500 rounded-lg text-white font-bold px-4'
                      initial={{ x: '100%' }}
                      animate={{ x: isHovered && currentIndex === index ? 0 : '100%' }}
                      transition={{ type: 'tween', duration: 0.2 }}
                      style={{ width: '80px' }}
                    >
                      Invest
                    </motion.div>
                  </button>
                </TableCell>
              </TableRow>
            ))}
            {/* <TableRow className='p-4 w-full lg:hidden'>
            <TableCell colSpan={2}>
              <Button
                variant='simple'
                className='text-primary-500 p-0 m-0 flex justify-between w-full col-span-2 font-semibold'
              >
                View all funds
                <ChevronRight className='h-5 w-5' />
              </Button>
            </TableCell>
          </TableRow> */}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={3} // Spanning across all columns to take full width
                className='text-center py-4' // Center text and add padding
                style={{
                  width: '100%',
                  height: '50vh',
                  textAlign: 'center',
                }}
              >
                <div className='flex flex-col justify-center items-center gap-y-6'>
                  <p> No funds in the selected filters...</p>

                  <Button onClick={clearAllFilter}>Reset Filters</Button>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        )}

        {/* {isLoading && (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={3} // Spanning across all columns to take full width
                className='text-center py-4' // Center text and add padding
                style={{
                  width: '100%',
                  height: '50vh',
                  textAlign: 'center',
                }}
              >
                <Loader />
              </TableCell>
            </TableRow>
          </TableBody>
        )} */}
      </Table>
    </div>
  )
}

export default BestPerformingTable
