'use client'
import { motion } from 'framer-motion'

import { cn } from '../../lib/utils'

const BlurIn = ({ word, className, duration = 0.8 }) => {
  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <motion.span
      initial='hidden'
      animate='visible'
      exit='hidden'
      transition={{ duration }}
      variants={fadeVariants}
      className={cn(
        'font-display text-center tracking-[-0.02em] drop-shadow-sm md:text-7xl md:leading-[5rem]',
        className,
      )}
    >
      {word}
    </motion.span>
  )
}

export default BlurIn
