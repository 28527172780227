import { useState, useEffect, useRef } from 'react'

/**
 * Custom hook for a timer that counts down from a given number of minutes.
 * @param {number} initialMinutes - Initial number of minutes for the timer.
 * @returns {Object} - Timer state and control functions.
 */
export const useTimer = (initialMinutes) => {
  const [time, setTime] = useState(initialMinutes * 60) // Convert minutes to seconds
  const [isActive, setIsActive] = useState(false)
  const intervalRef = useRef(null)

  // Start the timer
  const startTimer = () => {
    if (!isActive) {
      setIsActive(true)
    }
  }

  // Pause the timer
  const pauseTimer = () => {
    if (isActive) {
      setIsActive(false)
    }
  }

  // Reset the timer to the initial minutes
  const resetTimer = () => {
    setIsActive(false)
    setTime(initialMinutes * 60)
  }

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalRef.current)
            setIsActive(false)
            return 0
          }
          return prevTime - 1
        })
      }, 1000)
    } else {
      clearInterval(intervalRef.current)
    }

    return () => clearInterval(intervalRef.current)
  }, [isActive])

  // Convert time in seconds to minutes and seconds format
  const formattedTime = {
    minutes: Math.floor(time / 60),
    seconds: time % 60,
  }

  return {
    time: formattedTime,
    isActive,
    startTimer,
    pauseTimer,
    resetTimer,
  }
}
