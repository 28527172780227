import { useErrorPopupStore } from '../store/useErrorPopupStore'

export const useErrorPopup = () => {
  const { showErrorPopup } = useErrorPopupStore()

  return {
    showError: (title, description, onClose) => {
      showErrorPopup(title, description, onClose)
    },
  }
}
