import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { MutualFundsSIPs } from '../../store/MFStore'
import React, { useEffect, useState } from 'react'
import Loader from '../../Components/Loader/Loader'
import { Button } from '../../Components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '../..//Components/ui/card'
import { PiggyBank, Plus } from 'lucide-react'
import SipDialog from '../../Components/MutualFunds/SipDialog'
import SipTable from '../../Components/MutualFunds/SipTable'
import { Link } from 'react-router-dom'

const MFSips = () => {
  const sips = useRecoilValueLoadable(MutualFundsSIPs)
  const [open, setOpen] = useState(false)
  const [modifyOpen, setModifyOpen] = useState(false)
  const [modifiedInvestment, setModifiedInvestment] = useState(null)

  const refreshSIPs = useRecoilRefresher_UNSTABLE(MutualFundsSIPs)

  useEffect(() => refreshSIPs(), [])

  switch (sips.state) {
    case 'loading':
      return <Loader />

    case 'hasValue':
      return (
        <div className='bg-white min-h-screen'>
          {sips.contents?.message.toLowerCase() === 'no sip found for the specified user.' ? (
            <div className='h-3/5 flex items-center justify-center p-4'>
              <Card className='w-full max-w-sm text-center border-none shadow-none'>
                <CardHeader>
                  <PiggyBank className='w-12 h-12 text-primary mx-auto mb-4' />
                  <CardTitle className='text-xl'>No SIPs Found</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className='text-muted-foreground mb-6'>
                    Start your investment journey by creating your first Systematic Investment Plan.
                  </p>
                  <Link to='/dashboard/mutual-funds'>
                    <Button className='w-full'>
                      <Plus className='mr-2 h-4 w-4' /> Create SIP
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            </div>
          ) : (
            <div className='max-w-[1128px] pt-12 mx-auto'>
              <SipDialog
                open={open}
                setOpen={setOpen}
                modifyOpen={modifyOpen}
                setModifyOpen={setModifyOpen}
                modifiedInvestment={modifiedInvestment}
              />
              <SipTable
                sips={sips?.contents?.data}
                setModifiedInvestment={setModifiedInvestment}
                setModifyOpen={setModifyOpen}
                setOpen={setOpen}
              />
            </div>
          )}
        </div>
      )

    case 'hasError':
      return <div>error</div>

    default:
      return null
  }
}

export default MFSips
