import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from '../ui/dialog'
import tick from '../../assets/SVGS/success.gif'
import { useModal } from '../../hooks/use-modal-store'
import { useNavigate } from 'react-router-dom'

const MessagePopup = () => {
  const { isOpen, onClose, type, data } = useModal()
  const navigate = useNavigate()

  const isModalOpen = isOpen && type === 'openPopup'

  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  // }

  return (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent
        className='h-screen md:h-auto flex flex-col justify-center items-center md:p-6 md:w-[362px]'
        style={{
          background:
            data?.severity === 'success'
              ? 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #7EDD65 0%, #368421 100%)'
              : data?.severity === 'info'
              ? 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #DD9365 0%, #843C21 100%)'
              : 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #6597DD 0%, #213784 100%)',
        }}
      >
        <DialogHeader>
          <DialogTitle className='flex justify-center items-center'>
            <img src={tick} alt={data?.severity} />
          </DialogTitle>
          <DialogTitle className='font-DMSans font-semibold text-xl text-white text-wrap leading-7 tracking-[-0.04em]'>
            {data && data?.title}
          </DialogTitle>
          <DialogDescription className='font-DMSans font-normal text-sm text-white text-wrap leading-5'>
            {data && data?.message}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='justify-center flex-row mt-6'>
          <div className='w-full flex justify-center gap-x-6'>
            <DialogClose
              onClick={() => {
                onClose(), navigate('dashboard/mutual-funds')
              }}
              style={{ background: '#FFFFFF3D' }}
              className='h-10 w-[149px] rounded-[8px]'
            >
              <span className='font-DMSans font-medium text-sm text-white'>Close</span>
            </DialogClose>
            <button
              className=' h-10 w-[149px] rounded-[8px] bg-white'
              onClick={() => {
                onClose(), navigate('dashboard/mutual-funds')
              }}
            >
              <span
                className='font-DMSans font-medium text-sm'
                style={{
                  color:
                    data.severity === 'success'
                      ? '#3C8B27'
                      : data.severity === 'info'
                      ? '#853C22'
                      : '#223785',
                }}
              >
                Explore Funds
              </span>
            </button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default MessagePopup
